// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const PassengerAvatar = ({ name, style, image }) => {
  const getInitialsAvatar = name => {
    const splitedName = name?.split(' ');

    if (splitedName?.length > 1)
      return splitedName[0][0] + splitedName[splitedName.length - 1][0];
    else return splitedName[0][0];
  };

  return (
    <div className={[Style.passengerAvatar, style].join(' ')}>
      {image ? <img src={image} /> : getInitialsAvatar(name)}
    </div>
  );
};

export default PassengerAvatar;
