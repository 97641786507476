// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import Slider from 'react-slick';
import Style from './style.module.css';
import RightArrow from './right.svg';
import { reduceTripSegments } from '@store/reducers/cart/productHandler';
import TripSectionModal from './TripSectionModal';
import NextButton from '@components/NextButton';
import PrevButton from '@components/PrevButton';
import { sprintf } from 'sprintf-js';

import { useCurrentLocale, translate as t } from '@utils/translate';
import { capitalizeFirstLetter } from '@utils/functions';

import { useCurrentLang } from '@utils/translate';

const TripSection = ({ data }) => {
  const TEXTS = {
    PATH: t('segmentNofN'),
  };
  const [openDetails, setOpenDetails] = useState(false);
  const [cardPosition, setCardPosition] = useState('');
  const [flights, setFlights] = useState([]);
  const lang = useCurrentLang();
  const locale = useCurrentLocale();

  useEffect(() => {
    if (data.cart) {
      setFlights(reduceTripSegments(data.cart.products));
    }
  }, [data]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCardPosition(next),
  };
  const slider = React.createRef();

  const previous = () => {
    slider.current.slickPrev();
  };

  const next = () => {
    slider.current.slickNext();
  };

  return (
    <div className={Style.section}>
      {openDetails && (
        <TripSectionModal
          flights={flights}
          closeModal={() => setOpenDetails(false)}
        />
      )}
      <div>
        <Slider {...settings} ref={slider} className={Style.slider}>
          {flights.map((flight, index) => {
            const departureDate = flight.departureDate
              ? flight.departureDate
              : flight.flightDatetime || flight.departureDatetime;

            return (
              <div
                key={index}
                className={Style.path}
                onClick={() => setOpenDetails(true)}
              >
                <p className={Style.pathText}>
                  {sprintf(TEXTS.PATH, index + 1, flights.length)}
                </p>
                <div className={Style.pathCities}>
                  <p className={Style.departureCity}>
                    {flight.originSpot.address.city.name}
                  </p>
                  <img src={RightArrow} alt={'Para'} className={Style.arrow} />
                  <p className={Style.arriveCity}>
                    {flight.destinationSpot.address.city.name}
                  </p>
                </div>
                <p className={Style.date}>
                  {capitalizeFirstLetter(
                    format(parseISO(departureDate), "EEEE', 'PPPp", { locale })
                  )}
                </p>
              </div>
            );
          })}
        </Slider>
        {flights.length > 1 && (
          <div className={Style.buttons}>
            <div className={Style.prevButton}>
              <PrevButton
                disabled={cardPosition === 0}
                onClick={() => previous()}
              />
            </div>
            <div className={Style.nextButton}>
              <NextButton
                disabled={flights.length === cardPosition + 1}
                onClick={() => next()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TripSection;
