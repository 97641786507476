/**
 * Returns a mapped array of passengers ids
 *
 * @param {array} passengers Array of objects (Passengers)
 */
const mapPassengers = passengers => {
  const passengersList = passengers.map(passenger => {
    const obj = {
      id: passenger.id,
    };
    return obj;
  });
  return passengersList;
};

/**
 * Returns a mapped array of products
 *
 * @param {array} products Array of objects (Products)
 */
const mapProducts = products => {
  const productsList = products.map(product => {
    const obj = {
      id: product.id,
      data: {
        autoAttachPax: 1,
      },
    };
    return obj;
  });
  return productsList;
};

/**
 * Returns CART prepared for execPayment()
 *
 * @param {object} cart Cart data
 */
const formatCartForPayment = cart => {
  if (!cart) return null;

  try {
    const obj = {
      token: cart.token,
      products: mapProducts(cart.products),
    };
    return obj;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Return INVOICE prepared for execPayment()
 *
 * @param {object} address Address data (id)
 * @param {object} user User data (document, documentTypeId)
 */
const formatInvoiceForPayment = (address, user) => {
  if (!address || !user) return null;
  const rxOnlyNumbers = /\d+/g;

  try {
    const obj = {
      document: user.cpf.match(rxOnlyNumbers).join(''),
      documentTypeId: user.documentTypeId,
      address: { id: address.id },
    };
    return obj;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Return PASSENGERS prepared for execPayment()
 *
 * @param {array} passengers Array of objects (Passengers)
 */
const formatPassengersForPayment = passengers => {
  if (!passengers) return null;

  try {
    const passengersList = mapPassengers(passengers);
    return passengersList;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Return PAYMENTS prepared for execPayment()
 *
 * @param {object} paymentMethod Payment method data (type)
 * @param {object} card Card data (cardId, cvv)
 * @param {number} installments Installments data (amount, numberOfInstallments, currency)
 */
const formatPaymentMethodForPayment = (paymentMethod, card, installments) => {
  if (!paymentMethod || !card || !installments) return null;

  try {
    const obj = {
      paymentType: paymentMethod.type,
      amount: installments.amount,
      currency: installments.currency || 'BRL',
      creditCard:
        paymentMethod.type === 'CreditCard'
          ? {
              card: {
                id: card.cardId,
                cvv: card.cardCode,
              },
              numberOfInstallments: installments.numberOfInstallments,
            }
          : {},
    };
    return [obj];
  } catch (error) {
    console.error(error);
    return null;
  }
};

export {
  formatCartForPayment,
  formatInvoiceForPayment,
  formatPassengersForPayment,
  formatPaymentMethodForPayment,
};
