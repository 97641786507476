// Third party libs/components
import React, { useEffect, useState, memo } from 'react';
import P from 'prop-types';
import Style from './style.module.css';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import './swiper.config.css';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import { useStore, connect } from 'react-redux';
import {
  setSelectedPaymentMethod,
  deletePaymentMethod,
} from '@store/reducers/cart/operations';

// Components
import ConfirmDelete from './ConfirmDelete';
import CreditCard from '@components/CreditCard';
import Dialog from '@components/Dialog';

// Utils
import debounce from '@utils/debounce';
import { translate as t } from '@utils/translate';

const SliderComponent = ({
  items,
  deleteAction,
  breakpoints,
  setOpenConfirm,
  setCardToDelete,
  lang,
}) => {
  const store = useStore();
  const swiperParams = {
    direction: breakpoints['md'] ? 'horizontal' : 'vertical',
    slidesPerView: breakpoints['md'] ? 1 : 'auto',
    centeredSlides: true,
    effect: 'coverflow',
    mousewheel: breakpoints['md'] ? false : true,
    coverflowEffect: {
      rotate: 0,
      stretch: breakpoints['md'] ? 120 : 420,
      depth: 200,
      modifier: 1,
      slideShadows: false,
    },
    navigation: breakpoints['md']
      ? {}
      : {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    pagination: breakpoints['md']
      ? {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        }
      : {},
    shouldSwiperUpdate: true,
    rebuildOnUpdate: true,
    slideToClickedSlide: breakpoints['md'] ? false : true,
    on: {
      init: function() {
        if (items[0]) {
          const option = items[0];
          store.dispatch(setSelectedPaymentMethod(option));
        }
      },
      slideChange: function() {
        if (items[this.activeIndex]) {
          const option = items[this.activeIndex];
          store.dispatch(setSelectedPaymentMethod(option));
        } else store.dispatch(setSelectedPaymentMethod(null));
      },
    },
  };

  return (
    <div
      className={`payment-selector ${Style.creditCardSelectorContainer} ${
        items.length <= 1 ? 'single-item' : ''
      }`}
    >
      <Swiper {...swiperParams}>
        {items.map((card, index) => {
          if (!card) {
            return (
              <div key={index} className={Style.cardContainer}>
                <CreditCard />
              </div>
            );
          }

          return (
            <div key={index} className={Style.cardContainer}>
              <CreditCard
                data={{ ...card }}
                deleteAction={deleteAction}
                onDelete={() => {
                  setOpenConfirm(true);
                  setCardToDelete(card);
                }}
              />
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};

SliderComponent.propTypes = {
  items: P.array,
  deleteAction: P.bool,
  breakpoints: P.object,
  setOpenConfirm: P.func,
  setCardToDelete: P.func,
  lang: P.string,
};

const Slider = memo(SliderComponent);

const CreditCardSelector = ({
  lang,
  paymentMethods,
  breakpoints,
  deleteAction = false,
}) => {
  const TEXTS = {
    DELETE: t('delete'),
    CARD_DELETED: t('cardDeleted'),
    DELETING: t('deleting'),
  };

  const store = useStore();
  const [items, setItems] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(TEXTS.DELETE);

  function handleDelete() {
    setLoading(true);
    setLabel(TEXTS.DELETING + '...');
    debounce(() => {
      try {
        const {
          card: { id: cardId },
        } = cardToDelete;
        store.dispatch(deletePaymentMethod(cardId));
      } finally {
        setLabel(TEXTS.CARD_DELETED);
        setTimeout(() => {
          setLoading(false);
          setOpenConfirm(false);
        }, 600);
      }
    }, 2000);
  }

  useEffect(() => {
    const paymentWithNewCard = [...paymentMethods, null];
    setItems(paymentWithNewCard);
  }, [paymentMethods]);

  return (
    <>
      <Slider
        items={items}
        deleteAction={deleteAction}
        breakpoints={breakpoints}
        setOpenConfirm={setOpenConfirm}
        setCardToDelete={setCardToDelete}
        lang={lang}
      />
      {openConfirm && (
        <Dialog open={openConfirm} setOpen={setOpenConfirm}>
          <ConfirmDelete
            onCancel={() => setOpenConfirm(false)}
            onConfirm={handleDelete}
            loading={loading}
            currentLabel={label}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const contextState = state.cart;
  return {
    paymentMethods: contextState.paymentMethods,
    lang: state.general.language,
  };
};

CreditCardSelector.propTypes = {
  paymentMethods: P.array,
  breakpoints: P.object,
  deleteAction: P.bool,
  lang: P.string,
};

export default withBreakpoints(connect(mapStateToProps)(CreditCardSelector));
