import styled, { css, keyframes } from 'styled-components';

const COLORS = {
  grey: '#a5a9b4',
  darkGrey: '#858585',
  disabledGrey: '#E0E0E0',
  buttonGreen: '#009688',
  buttonHoverGreen: '#01BEA4',
  lightGrey: '#616161',
  overlayDark: '#33333322',
  panelShadow: '#0000004D',
  primaryGreen: '#009688',
  white: '#ffffff',
  black: '#000000',
};

const animationIn = keyframes`
  0% { 
    right: -50px;
    opacity: 0; 
  }
  
  100% { 
    right: 0; 
    opacity: 1; 
  }
`;

export const Container = styled.div`
  animation: ${animationIn} 0.5s ease 0.2s forwards;
  display: ${({ show, shared }) => (show || !shared ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  height: 80%;
  width: 80%;
  opacity: 0;
  position: relative;

  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const Title = styled.span`
  font-size: 22px;
  font-weight: 700;
`;

export const SubTitle = styled.span`
  margin: 10px 0 30px 0;
  color: ${COLORS.grey};
  font-size: 2em;
  font-weight: 300;
`;

export const Modal = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 3px 30px ${COLORS.panelShadow};
  height: 75%;
  overflow-y: auto;
  z-index: 20;
  animation: animationIn 0.7s;

  @media (min-width: 1024px) {
    height: 75%;
  }
`;

export const CloseButton = styled.div`
  width: 26px;
  height: 26px;
  padding: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 24 24'%3E%3Cpath fill='%23616161' d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  top: 11%;
  right: 10%;
`;
