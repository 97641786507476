// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import { translate as t } from '@utils/translate';

const ErrorAlert = ({
  title,
  subtitle,
  description,
  errorMsg,
  buttonLabel,
  onClick,
}) => {
  const TEXTS = {
    TITLE: title || 'Opss...',
    SUBTITLE: subtitle || t('somethingWrong'),
    DESCRIPTION: description || t('verifyData'),
    ERROR_MSG: errorMsg || t('paymentFailed') + ' - Erro nº 7899',
    BUTTON_LABEL: buttonLabel || t('backToPayment'),
  };

  return (
    <div className={Style.container}>
      <div className={Style.content}>
        <h1 className={Style.title}>{TEXTS.TITLE}</h1>
        <p className={Style.subTitle}>{TEXTS.SUBTITLE}</p>
        <p className={Style.description}>{TEXTS.DESCRIPTION}</p>
        <button onClick={onClick} className={Style.button}>
          {TEXTS.BUTTON_LABEL}
        </button>
        <div className={Style.contentError}>
          <span className={Style.errorText}>{TEXTS.ERROR_MSG}</span>
        </div>
      </div>
    </div>
  );
};

export default ErrorAlert;
