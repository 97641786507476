// react/third-part modules
import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import { useStore } from 'react-redux';

// component modules
import Layout from '../components/layout';
import Checkout from '../app/Checkout';

// contexts/hooks/others
import { setLanguage, setCurrency } from '@store/reducers/general/operations';
import { getLangFromLocalStorage, getLangParam } from '@utils/language';
import { getCurrencyFromLocalStorage, getCurrencyParam } from '@utils/currency';
import { getCurrencyByCode } from '@gatsby-local-plugin/flapper-gatsby-currencies/';

const CheckoutPage = ({ location }) => {
  const path = location.pathname;
  const store = useStore();
  const urlParams = new URLSearchParams(location.search);
  const translations = [
    { path: path, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];
  const [mustLogIn, setMustLogIn] = useState(false);

  // get/set language
  const lang = urlParams.get('lang');
  if (lang) store.dispatch(setLanguage(lang));

  useEffect(() => {
    // get/set currency
    const currency = getCurrencyParam() || getCurrencyFromLocalStorage();
    const language = getLangParam() || getLangFromLocalStorage();
    store.dispatch(setCurrency(getCurrencyByCode(currency)));
    store.dispatch(setLanguage(language));
  }, []);

  const onCloseLogin = () => {
    setMustLogIn(false);
    window.location.reload();
  };

  return (
    <Layout
      SearchHeader={false}
      translations={translations}
      useTranslationsPath={false}
      solidHeader="white"
      showNewsletter={false}
      showFooter={false}
      closeLogin={onCloseLogin}
      isPrivate={true}
    >
      <Router basepath="/checkout">
        <Checkout path="/:token" />
      </Router>
    </Layout>
  );
};

export default CheckoutPage;
