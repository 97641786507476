import styled, { css, keyframes } from 'styled-components';

import TextInput from '@components/FormInputs/TextInput';

const COLORS = {
  grey: '#a5a9b4',
  darkGrey: '#858585',
  disabledGrey: '#E0E0E0',
  buttonGreen: '#009688',
  buttonHoverGreen: '#01BEA4',
  lightGrey: '#616161',
  ligthRed: '#fb6f91',
  overlayDark: '#33333322',
  panelShadow: '#0000004D',
  primaryGreen: '#009688',
  white: '#ffffff',
  black: '#000000',
};

const animationIn = keyframes`
  0% { 
    right: 50px;
    opacity: 0; 
  }
  
  100% { 
    right: 0; 
    opacity: 1; 
  }
`;

export const Form = styled.form`
  animation: ${animationIn} 0.5s ease 0.5s forwards;
  display: ${({ show }) => (show ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-start;
  height: 80%;
  width: 80%;
  opacity: 0;
  position: relative;

  @media (min-width: 1024px) {
    width: 35%;
  }
`;

export const Title = styled.span`
  font-size: 22px;
  font-weight: 700;
`;

export const SubTitle = styled.span`
  margin: 10px 0 30px 0;
  color: ${COLORS.grey};
  font-size: 2em;
  font-weight: 300;
`;

export const Description = styled.span`
  color: ${COLORS.grey};
  font-size: 1.3em;
  font-weight: 300;
  margin-top: 25px;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const Input = styled(TextInput)`
  width: 100%;
  height: 57px;
  margin-top: 20px;
  border-radius: 16px;
  border: 2px solid ${COLORS.disabledGrey};
  padding: 2px 23px 0;
  font-weight: 600;
  color: ${COLORS.black};
  transition: box-shadow 0.2s ease-out, border-color 0.2s ease-out;
  transition: border-color 0.2s ease-out, border-color 0.2s ease-out;
  font-size: 16px;

  &:focus {
    border-color: #01bfa5;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const CancelButton = styled.button`
  border-radius: 8px;
  background-color: ${COLORS.white};
  border: none;
  color: ${COLORS.primaryGreen};
  font-size: 15px;
  font-weight: 600;
  height: 47px;
  padding: 2px 41px 0;
  cursor: pointer;

  @media (min-width: 1024px) {
    margin-right: 30px;
  }
`;

export const UpdateButton = styled.button`
  border-radius: 8px;
  background-color: ${props => (props.disabled ? '#ccc' : COLORS.primaryGreen)};
  border: none;
  color: ${COLORS.white};
  font-size: 15px;
  font-weight: 600;
  height: 47px;
  padding: 2px 41px 0;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${props =>
    !props.disabled
      ? css`
          &:hover {
            box-shadow: 0 0 15px #1113;
          }

          &:active {
            transform: translateY(10px);
          }
        `
      : css`
          cursor: not-allowed;
        `}
`;

export const Error = styled.span`
  color: ${COLORS.ligthRed};
  margin: 10px 0px 0px 10px;
  font-weight: 700;
  align-self: flex-start;
`;
