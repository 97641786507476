// Third party libs/components
import React, { useState, useEffect } from 'react';
import P from 'prop-types';
import Style from './style.module.css';
import { updateAddress } from '@store/reducers/address/operations';
import { setInvoicingAddress } from '@store/reducers/cart/operations';
import { useStore } from 'react-redux';
import { connect } from 'react-redux';

// Components
import EditUserModal from './EditUserModal';
import CreditCardAddress from '@components/CreditCardAddress';

// Services
import { getUser } from '../../services/auth';

const InvoicingManager = ({
  userData,
  address,
  selectedAddress,
  nameLabel,
  editButton,
  noAddress,
}) => {
  const [loading, setIsLoading] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [user, setUser] = useState(getUser());
  const [editAddress, setEditAddress] = useState(false);
  const [addressLine, setAddressLine] = useState('');
  const store = useStore();

  function handleUpdateAddress(data) {
    store.dispatch(updateAddress(data));
    setEditAddress(false);
  }

  const handleSelectAddress = address => {
    store.dispatch(setInvoicingAddress(address));
    setEditAddress(false);
  };

  useEffect(() => {
    if (address.addresses.length) {
      if (!selectedAddress) {
        store.dispatch(setInvoicingAddress(address.addresses[0]));
      }
    }
  }, [address]);

  useEffect(() => {
    if (selectedAddress) {
      let fullAddress = {
        line: selectedAddress.line || '',
        cityName: selectedAddress.cityName || '',
        stateName: selectedAddress.stateName || '',
        zipCode: selectedAddress.zipCode || '',
        countryName: selectedAddress.countryName || '',
      };
      setAddressLine(
        `${fullAddress.line} - ${fullAddress.cityName} - ${fullAddress.stateName}, ${fullAddress.zipCode} - ${fullAddress.countryName}`
      );
    }
  }, [selectedAddress]);

  return (
    <>
      {editAddress && (
        <CreditCardAddress
          isOpened={editAddress}
          onClose={() => setEditAddress(false)}
          onSave={handleSelectAddress}
          isEdit={true}
          onUpdate={data => handleUpdateAddress(data)}
        />
      )}
      {loading || userData === null ? (
        <p className={Style.load}>Loading...</p>
      ) : (
        <section className={Style.content}>
          {!user.identityDocument ||
          !user.birthdayDate ||
          !user.documentTypeId ||
          editUser ? (
            <EditUserModal
              userData={user}
              isShared={true}
              closeModal={() => setEditUser(false)}
            />
          ) : (
            ''
          )}
          <div className={Style.userArea}>
            <div className={Style.userData}>
              <span className={Style.title}>
                {userData.fullname ? userData.fullname : nameLabel}
              </span>
              <span className={Style.text}>{userData.identityDocument}</span>
            </div>
            <span
              className={Style.editButton}
              onClick={() => setEditUser(true)}
            >
              {editButton}
            </span>
          </div>

          {
            <div className={Style.adressArea}>
              <div className={Style.addressData}>
                <span className={Style.title}>{`${
                  selectedAddress && selectedAddress.name
                    ? selectedAddress.name
                    : noAddress
                }`}</span>
                <span className={Style.text}>{addressLine}</span>
              </div>
              <span
                className={Style.editButton}
                onClick={() => setEditAddress(true)}
              >
                {editButton}
              </span>
            </div>
          }
        </section>
      )}
    </>
  );
};

InvoicingManager.propTypes = {
  userData: P.object,
  address: P.object,
  selectedAddress: P.object,
  nameLabel: P.string,
  editButton: P.string,
  noAddress: P.string,
};

const mapStateToProps = state => ({
  userData: state.cart.userData,
  address: state.address,
  selectedAddress: state.cart.selectedInvoicingAddress,
});

export default connect(mapStateToProps)(InvoicingManager);
