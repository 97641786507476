// TODO: REFACTORING
// react/third-part modules
import React, { useRef, useState, useEffect } from 'react';
import Style from './style.module.css';
import { useSelector } from 'react-redux';

// component modules
import PassengerAvatar from '@components/PassengerAvatar';

// contexts/hooks/others
import { translate as t } from '@utils/translate';

// hook for resizing
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const PassengersCollapsedList = ({
  handleClickShowAll,
  handleClickShowPassenger,
}) => {
  const thumbsWrapperRef = useRef(null);
  const size = useWindowSize();
  const passengers = useSelector(state =>
    state.cart.passengers.filter(passenger => passenger.selected)
  );
  const [thumbsWrapperWidth, setThumbsWrapperWidth] = useState(0);

  useEffect(() => {
    const thumbsWrapperWidth = !thumbsWrapperRef.current
      ? 0
      : thumbsWrapperRef.current.clientWidth;
    setThumbsWrapperWidth(thumbsWrapperWidth);
  });

  // calculate the number of thumbs to be displayed
  const calcNumberOfThumbs = () => {
    const nThumbs = thumbsWrapperWidth ? parseInt(thumbsWrapperWidth / 50) : 0;
    return nThumbs;
  };

  // calculate the number of hidden thumbs
  const calcHiddenThumbs = () => {
    const nHiddenThumbs = passengers.length - calcNumberOfThumbs() - 1;
    if (nHiddenThumbs < 0) return 0;
    return nHiddenThumbs;
  };

  const clickToShow = id => {
    if (handleClickShowPassenger) handleClickShowPassenger(id, true);
  };

  const TEXTS = {
    SEE_ALL: t('seeAll'),
  };

  return (
    <div className={Style.passengersCollapsedList}>
      {passengers.length > 0 ? (
        <span className={Style.showAll} onClick={() => handleClickShowAll()}>
          {TEXTS.SEE_ALL}
        </span>
      ) : null}
      <div className={Style.thumbsWrapper} ref={thumbsWrapperRef}>
        {passengers.map((passenger, index) => {
          return index <= calcNumberOfThumbs() ? (
            <div key={index}>
              <div
                className={Style.passengerAvatarContainer}
                onClick={() => clickToShow(passenger.id)}
                key={index}
              >
                <PassengerAvatar
                  name={passenger.firstName}
                  style={
                    handleClickShowPassenger
                      ? Style.clickableThumb
                      : Style.thumb
                  }
                  image={passenger.imageUrl}
                />
              </div>
            </div>
          ) : null;
        })}
      </div>
      {calcHiddenThumbs() > 0 ? (
        <span className={Style.hiddenThumbs}>+{calcHiddenThumbs()}</span>
      ) : null}
    </div>
  );
};

export default PassengersCollapsedList;
