// TODO: REFACTORING
// react/third-part modules
import React, { useState, useEffect } from 'react';
import Style from './style.module.css';
import PassengersList from '@components/PassengersList';
import { useStore, useSelector } from 'react-redux';

// component modules

// contexts/hooks/others

const PassengersManager = ({ setFocusSelection, showMiniList }) => {
  const seats = useSelector(state => state.cart.seats);
  return (
    <div className={Style.passengersManager}>
      <PassengersList
        setFocusSelection={setFocusSelection}
        showMiniList={showMiniList}
      />
    </div>
  );
};

export default PassengersManager;
