import useRequest from '@utils/useRequest';
import { programEVEBH, programEVEinfo, apiHost } from '@utils/constants';

const flightProgramBH = payload => {
  const url = apiHost + programEVEBH;

  return useRequest({
    url: url,
    method: 'post',
    useSession: true,
    data: payload,
  });
};

const getFlightEVEInfo = (flightId, userId) => {
  const url =
    apiHost + programEVEinfo + `?flightId=${flightId}&userId=${userId}`;

  return useRequest({
    url: url,
    method: 'get',
    useSession: false,
  });
};

export { flightProgramBH, getFlightEVEInfo };
