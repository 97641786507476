import React from 'react';
import * as Styled from './SharedForm.styles';
import { useForm } from 'react-hook-form';

import { translate as t } from '@utils/translate';

const SharedForm = ({
  fullName,
  identityDocument,
  show,
  failData,
  handleUpdateData,
}) => {
  const { register, errors, handleSubmit, formState } = useForm({
    defaultValues: {
      fullName: fullName,
      userDocument: identityDocument,
    },
    mode: 'onChange',
  });

  const { isValid } = formState;

  const TEXTS = {
    INVOICE_SUBTITLE: t('invoiceSubtitle'),
    DOCUMENT: t('documentInput'),
    FULL_NAME: t('fullName'),
    PROCEED: t('proceed'),
    UPDATE_DATA: t('updateData'),
    INVOICING: t('invoicingData'),
    REQUIRED_FIELD: t('requiredField'),
  };

  const SCHEMA = {
    fullName: {
      name: 'fullName',
      placeholder: TEXTS.FULL_NAME + ' *',
      validation: {
        required: { value: true, message: TEXTS.REQUIRED_FIELD },
      },
    },
    userDocument: {
      name: 'userDocument',
      placeholder: TEXTS.DOCUMENT + ' *',
      validation: {
        required: { value: true, message: TEXTS.REQUIRED_FIELD },
      },
    },
  };

  const onSubmit = ({ userName, userDocument }) => {
    handleUpdateData(userName, userDocument);
  };

  const errorStyles = {
    color: '#cd6e6e',
    position: 'relative',
    top: '2px',
    fontSize: '12px',
    left: '4px',
  };

  return (
    <Styled.Form show={show} onSubmit={handleSubmit(onSubmit)}>
      <Styled.Title>{TEXTS.INVOICING}</Styled.Title>
      <Styled.Description>{TEXTS.INVOICE_SUBTITLE}</Styled.Description>
      <Styled.InputContent>
        <Styled.Input
          type="text"
          register={register}
          errors={errors}
          errorStyles={errorStyles}
          {...SCHEMA.fullName}
        />
        <Styled.Input
          type="text"
          register={register}
          errors={errors}
          errorStyles={errorStyles}
          {...SCHEMA.userDocument}
        />
      </Styled.InputContent>
      <Styled.Buttons>
        <Styled.UpdateButton type="submit" disabled={!isValid}>
          {failData ? TEXTS.PROCEED : TEXTS.UPDATE_DATA}
        </Styled.UpdateButton>
      </Styled.Buttons>
    </Styled.Form>
  );
};

export default SharedForm;
