/* eslint-disable react/display-name */
// react/third-part modules
import React, { useState } from 'react';
import P from 'prop-types';
import Style from './style.module.css';

// Assets
import Minus from '@images/minus.svg';

// Store
import { useStore } from 'react-redux';
import { toggleSelectedPassenger } from '@store/reducers/cart/operations';

// Component
import PassengerAvatar from '@components/PassengerAvatar';

// Utils
import { translate as t } from '@utils/translate';

const PassengersListItem = React.forwardRef(
  (
    {
      passenger,
      isSelectable,
      canSelect,
      onToggleSelection,
      onClickToShow,
      onRemoveItem,
      index,
    },
    ref
  ) => {
    const [selected, setSelected] = useState(passenger.selected);
    const store = useStore();

    if (ref) {
      ref.current[passenger.id] = { setSelected };
    }

    const toggleSelection = () => {
      const isSelected = !selected;

      setSelected(isSelected);

      if (onToggleSelection) onToggleSelection(passenger.id, isSelected);
    };

    const clickToShow = () => {
      if (onClickToShow) onClickToShow(passenger.id, true);
    };

    const handleRemovePassenger = id => {
      store.dispatch(toggleSelectedPassenger(id, false));
      if (onRemoveItem) onRemoveItem(id);
    };
    const TEXTS = {
      REMOVE_ALT: t('remove'),
      REMOVE_PASSENGER: t('removePassenger'),
    };
    return (
      <div className={Style.passengersListItem}>
        <div className={Style.passengerAvatarContainer} onClick={clickToShow}>
          <PassengerAvatar
            name={passenger.firstName}
            image={passenger.imageUrl}
          />
        </div>
        <div className={Style.passengerInfoWrapper}>
          <div className={Style.passengerInfo} onClick={clickToShow}>
            <div
              className={Style.passengerName}
            >{`${passenger.firstName} ${passenger.lastName}`}</div>
            <div className={Style.passengerPhone}>
              {passenger.phoneNumber
                ? passenger.phoneNumber.replace(
                    /(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/,
                    '$1 ($2) $3 $4-$5'
                  )
                : ''}
            </div>
          </div>

          {!isSelectable && (
            <div className={Style.actions}>
              <div className={Style.actionRemove}>
                <img
                  src={Minus}
                  alt={TEXTS.REMOVE_ALT}
                  title={TEXTS.REMOVE_PASSENGER}
                  onClick={() => handleRemovePassenger(passenger.id, false)}
                />
              </div>
            </div>
          )}

          {isSelectable && canSelect && (
            <div className={Style.selectionWrapper}>
              <div
                data-test={`passengerListItem.checkbox-${index}`}
                onClick={() => toggleSelection()}
                className={[
                  Style.passengerCheckbox,
                  selected ? Style.selected : '',
                ].join(' ')}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

PassengersListItem.propTypes = {
  passenger: P.object,
  isSelectable: P.bool,
  canSelect: P.bool,
  onToggleSelection: P.func,
  onClickToShow: P.func,
  onRemoveItem: P.func,
  showModal: P.func,
  index: P.number,
};

export default PassengersListItem;
