// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import { useStore, connect, useSelector } from 'react-redux';
import {
  setSelectedInstallments,
  fetchInstallments,
} from '@store/reducers/cart/operations';
import { translate as t } from '@utils/translate';
import CardConfirmCode from '@components/CardConfirmCode';
import Loader from '@components/Loader';
import Style from './style.module.css';
import { formatCurrency } from '@utils/currency';

import InfoIcon from '@images/info.png';

import AnimatedTooltip from '@components/AnimatedComponents/AnimatedTooltip';

const InstallmentsSelector = ({
  onClick,
  onSelect,
  onCancelSelection,
  selectedPaymentMethod,
  totalPrice,
  cartToken,
  currency,
  onExecPayment,
}) => {
  const store = useStore();
  const selectedCurrency = useSelector(state => state.general.currency);
  const [isLoading, setIsLoading] = useState(false);
  const [showInstallments, setShowInstallments] = useState(false);
  const [installmentsSchemes, setInstallmentsSchemes] = useState();
  const [installmentSelected, setInstallment] = useState();
  const [markedInstallment, setMarked] = useState();
  const [selectedCard, setSelectedCard] = useState(false);
  const [hasCurrencyConversion, setHasCurrencyConversion] = useState(false);

  useEffect(() => {
    if (selectedPaymentMethod) {
      const { paymentGatewayId } = selectedPaymentMethod?.card;

      setHasCurrencyConversion(paymentGatewayId === '1' && currency !== 'BRL');
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (selectedPaymentMethod) {
      const cardId = selectedPaymentMethod.card.id;
      if (cardId && cartToken) {
        setIsLoading(true);
        try {
          store
            .dispatch(
              fetchInstallments({
                cartToken: cartToken,
                currency: selectedCurrency?.code || 'BRL',
                cardIds: cardId,
              })
            )
            .then(res => {
              if (res.result.status === 0);
              setInstallmentsSchemes(res.installmentsSchemes);
              setIsLoading(false);
            })
            .catch(error => console.error(error));
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    } else {
      setInstallmentsSchemes([
        {
          amount: totalPrice,
          currency: currency,
          installments: [
            {
              amount: totalPrice,
              currency: currency,
              discount: 0,
              discountType: 'percentage',
              installmentPrice: totalPrice,
              numberOfInstallments: '1',
            },
          ],
        },
      ]);
    }
  }, [selectedPaymentMethod, totalPrice, cartToken]);

  useEffect(() => {
    if (installmentsSchemes) {
      if (installmentsSchemes.length > 0) {
        store.dispatch(
          setSelectedInstallments(installmentsSchemes[0].installments[0])
        );
        setMarked(installmentsSchemes[0].installments[0]);
        setInstallment(installmentsSchemes[0].installments[0]);
      }
    }
  }, [installmentsSchemes]);

  function selectedInstallment(installment) {
    store.dispatch(setSelectedInstallments(installment));
    setInstallment(installment);
    setMarked(installment);
    setShowInstallments(false);
    if (onSelect) onSelect();
  }

  function handleNextButton() {
    if (onSelect()) {
      selectedInstallment(markedInstallment);
      setSelectedCard(true);
    }
  }

  function handleCancelButton() {
    setShowInstallments(false);
    setSelectedCard(false);
    if (onCancelSelection) onCancelSelection();
  }

  const renderInstallments = installment => {
    return (
      <span
        key={installment.numberOfInstallments}
        className={
          markedInstallment &&
          installment.numberOfInstallments ===
            markedInstallment.numberOfInstallments
            ? Style.installmentSelected
            : Style.installmentsValue
        }
        onClick={() => setMarked(installment)}
      >{`${installment.numberOfInstallments}x 
        ${formatCurrency({
          value: installment.installmentPrice,
          currency: installment.currency,
        })}`}</span>
    );
  };

  const TEXTS = {
    INSTALLMENT_OPTIONS: t('installmentOptions'),
    INSTALLMENT: t('installment'),
    NEXT: t('next'),
    INFO_CURRENCY_CONVERSION: t('infoCurrencyConversion'),
  };

  return (
    <>
      {!selectedCard ? (
        installmentsSchemes ? (
          <div className={Style.topContent}>
            <div className={Style.content}>
              <div className={Style.titleWrapper}>
                <h3>{TEXTS.INSTALLMENT_OPTIONS}</h3>
                {hasCurrencyConversion && (
                  <div className={Style.infoIconWrapper}>
                    <AnimatedTooltip
                      tooltipStyles={{
                        maxWidth: '300px',
                      }}
                      label={TEXTS.INFO_CURRENCY_CONVERSION}
                    >
                      <img className={Style.infoIcon} src={InfoIcon} />
                    </AnimatedTooltip>
                  </div>
                )}
                {hasCurrencyConversion && (
                  <span className={Style.infoBar}>
                    {TEXTS.INFO_CURRENCY_CONVERSION}
                  </span>
                )}
              </div>
              <div className={Style.installmentsContainer}>
                <span className={Style.title}>{TEXTS.INSTALLMENT}</span>
                <div className={Style.installments}>
                  {!isLoading && installmentsSchemes.length > 0
                    ? installmentsSchemes[0].installments.map(installment => {
                        return renderInstallments(installment);
                      })
                    : isLoading && <Loader transparent={true}></Loader>}
                </div>
              </div>

              <div className={Style.buttons}>
                <button
                  onClick={() => handleNextButton()}
                  className={[
                    Style.nextButton,
                    !selectedPaymentMethod ? Style.disabled : '',
                  ].join(' ')}
                  disabled={!selectedPaymentMethod}
                >
                  {TEXTS.NEXT}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )
      ) : (
        <CardConfirmCode
          cancelSelection={handleCancelButton}
          onExecPayment={onExecPayment}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    totalPrice: state.cart.totalPrice,
    cartToken: state.cart?.cartData?.token,
    selectedPaymentMethod: state.cart.selectedPaymentMethod,
    selectedInstallments: state.cart.selectedInstallments,
  };
};

export default connect(mapStateToProps)(InstallmentsSelector);
