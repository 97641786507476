import styled, { css, keyframes } from 'styled-components';

const checkAnimation = keyframes`
  from {
    stroke-dasharray: 100px;
    stroke-dashoffset: -20px;
  } to {
    stroke-dashoffset: 0px;
  }
`;

const loading = keyframes`
  to {
    background-position: 0%;
  }
`;

const Container = styled.div`
  width: 100%;
  max-height: 470px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  user-select: none;
`;

const FormScrollContainer = styled.form`
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px;
  margin: 0;
`;

const cssInput = {
  text: css`
    border-radius: 10px;
    height: 40px;
    border: none;
    outline: none;
    box-shadow: 0px 5px 15px #3333;
    transition: all 0.1s ease-in-out;
    margin-left: 20px;
    padding: 0 20px;
    min-width: 100px;
    width: 100%;
    max-width: 140px;
    &:focus {
      border: 2px solid #429488;
    }
  `,

  radio: css`
    margin-right: 10px;
  `,
};

const Input = styled.input`
  cursor: pointer;

  ${props => {
    return cssInput[props.type] || null;
  }}
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
`;

const InputsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;

const InfoTitle = styled.p`
  margin: 0;
`;

const ContainerActions = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #fff;
  border: none;
  background-color: transparent;
  padding: 14px 0;
  font-weight: 600;
  border-radius: 8px;
  width: ${props => (props.loading ? '100%' : 'calc(50% - 5px)')};
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 5px 0;
  background-repeat: no-repeat;
  background-size: 200%;
  max-width: 200px;

  ${props =>
    props.loading &&
    css`
      background-color: #424242;
      background-image: linear-gradient(to right, #009688 50%, #424242 50%);
      background-repeat: no-repeat;
      background-size: 200%;
      background-position: 100%;
      color: #ffffff;
      animation: ${loading} 2s ease-in-out forwards;
    `}

  ${props =>
    !props.loading &&
    css`
      background-image: linear-gradient(to right, #009688 50%, #424242 50%);
      background-position: 100%;
    `}

  &:disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;

    &:active {
      transform: none;
    }
  }

  &:active {
    transform: translateY(10px);
  }
`;

const CheckBox = styled.div`
  border: 1px solid #666;
  width: 20px;
  height: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 6px;
  margin-right: 20px;
  display: grid;
  place-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${props => {
    if (props.checked) {
      return css`
        border: none;
        background-color: #429488;
        box-shadow: 0 0 3px #429488;
      `;
    }
  }}

  svg {
    transform: scale(0.7);
    animation: ${checkAnimation} 0.3s ease-in-out;
  }
`;

const showMsg = keyframes`
  0% {
      opacity: 0;
      transform: scale(.7);
  }

  100% {
      opacity: 1;
      transform: scale(1);
  }
`;

const Error = styled.span`
  width: 70%;
  display: block;
  /* position: absolute; */
  /* bottom: 50px; */
  margin: 10px auto 0;
  text-align: center;
  color: #fff;
  background-color: #ca5050;
  padding: 5px 0;
  border-radius: 10px;
  font-weight: 500;
  animation: ${showMsg} 0.3s ease-in 1 forwards;
`;

export {
  Container,
  Input,
  Label,
  InfoTitle,
  ContainerActions,
  FormScrollContainer,
  Button,
  InputsContainer,
  CheckBox,
  Error,
};
