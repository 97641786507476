// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import { useStore, connect } from 'react-redux';
import Style from './style.module.css';
import { setSelectedCardCode } from '@store/reducers/cart/operations';
import { translate as t } from '@utils/translate';

const CardConfirmCode = ({
  cancelSelection,
  selectedPaymentMethod,
  onExecPayment,
}) => {
  const [code, setCode] = useState('');
  const store = useStore();
  const TEXTS = {
    TITLE: t('confirmCvvTitle'),
    DESCRIPTION: t('confirmCvvDescription'),
    CONFIRM_BUTTON_LABEL: t('confirmCvvButtonOK'),
    CANCEL_BUTTON_LABEL: t('cancel'),
  };

  function handleFormat(value) {
    const valueFormat = value.replace(/[^0-9]/g, '');
    setCode(valueFormat);
  }

  useEffect(() => {
    handleDispatchCardCode(code);
  }, [code]);

  const handleDispatchCardCode = cardCode => {
    store.dispatch(
      setSelectedCardCode({
        cardId: selectedPaymentMethod.card.id,
        cardCode: cardCode,
      })
    );
  };

  const handleExecPayment = () => {
    cancelSelection();
    onExecPayment();
  };

  return (
    <div
      className={[
        Style.container,
        code.length >= 3 ? Style.containerHighlighted : '',
      ].join(' ')}
    >
      <div className={Style.content}>
        <h2 className={Style.title}>{TEXTS.TITLE}</h2>
        <p className={Style.description}>{TEXTS.DESCRIPTION}</p>
        <div className={Style.inputContent}>
          <div className={Style.card}>
            <div className={Style.lineCard} />
            <div className={Style.cardItems}>
              <span className={Style.cardCode}>****</span>
            </div>
          </div>
          <input
            className={Style.cvvInput}
            type="text"
            placeholder="CVV"
            pattern="[0-9]"
            minLength={3}
            maxLength={4}
            value={code}
            onChange={e => handleFormat(e.target.value)}
          />
        </div>
        <div className={Style.buttons}>
          <button
            className={Style.nextButton}
            disabled={code.length < 3}
            onClick={handleExecPayment}
          >
            {TEXTS.CONFIRM_BUTTON_LABEL}
          </button>
          <button className={Style.cancelButton} onClick={cancelSelection}>
            {TEXTS.CANCEL_BUTTON_LABEL}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedPaymentMethod: state.cart.selectedPaymentMethod,
  selectedInstallments: state.cart.selectedInstallments,
});

export default connect(mapStateToProps)(CardConfirmCode);
