import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import * as Styled from './FormCheckout.styles';

// Components
import Checkbox from './Checkbox';

// Services
import { flightProgramBH } from '@services/eve';
import ReactInputMask from 'react-input-mask';

// utils
import { translate as t } from '@utils/translate';

const FormCheckout = ({ hasCheckbox, flightId, onSuccess }) => {
  const [allowedShareInfo, setAllowedShareInfo] = useState(false);
  const [allowedHelicopter, setAllowedHelicopter] = useState(false);
  const [onBoardForm, setOnBoardFrom] = useState(null);
  const [flightCode, setFlightCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getRadioValue = e => {
    setOnBoardFrom(e.target.value);
  };

  const TEXTS = {
    CHECKBOX_LABEL: t('eveBhCheckbox'),
    RADIO_BUTTON_LABEL: t('eveBhVc'),
    EVE_FLIGHT_NUMBER: t('eveFlightNumber'),
    SAVE: t('save'),
    YES: t('yes'),
    NO: t('no'),
    EVE_QUESTION_HELICOPTER: t('eveQuestionHelicopter'),
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await flightProgramBH({
      eve: {
        flightId: flightId,
        allowed_share_info: allowedShareInfo ? 1 : 0,
        onboard_from: onBoardForm,
        flight_code: flightCode,
        aware_flight_of_helicopter: allowedHelicopter ? 1 : 0,
      },
    });

    if (data.result.status !== 0) {
      const showError = setTimeout(() => {
        setError(true);
        clearTimeout(showError);
      }, 2000);

      const hideError = setTimeout(() => {
        clearTimeout(hideError);
        setLoading(false);
        setError(false);
      }, 4000);
    } else {
      setTimeout(() => {
        setLoading(false);
        onSuccess();
      }, 2200);
    }
  };

  useEffect(() => {
    setFlightCode('');
  }, [onBoardForm]);

  useEffect(() => {
    const isComplete = !flightCode.includes('_') && !!flightCode;

    const caseOne = onBoardForm === 'VC' && isComplete && allowedHelicopter;
    const caseTwo =
      onBoardForm !== 'VC' && !isComplete && !!onBoardForm && allowedHelicopter;

    if (caseOne || caseTwo) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [onBoardForm, flightCode, allowedHelicopter]);

  return (
    <Styled.Container>
      <Styled.FormScrollContainer>
        {hasCheckbox && (
          <Styled.Label>
            <Checkbox
              checked={allowedShareInfo}
              onClick={() => setAllowedShareInfo(!allowedShareInfo)}
            />
            {TEXTS.CHECKBOX_LABEL}
          </Styled.Label>
        )}

        <Styled.Label>
          <Checkbox
            checked={allowedHelicopter}
            onClick={() => setAllowedHelicopter(!allowedHelicopter)}
          />
          {TEXTS.EVE_QUESTION_HELICOPTER}&nbsp;<sup>*</sup>
        </Styled.Label>

        <Styled.InfoTitle>{TEXTS.RADIO_BUTTON_LABEL}</Styled.InfoTitle>
        <Styled.InputsContainer onChange={e => getRadioValue(e)}>
          <Styled.Label>
            <Styled.Input type="radio" name="flight" value="VC" />
            <span style={{ textTransform: 'capitalize' }}>{TEXTS.YES}</span>
          </Styled.Label>
          <Styled.Label>
            <Styled.Input type="radio" name="flight" value="CNF" />
            <span style={{ textTransform: 'capitalize' }}>{TEXTS.NO}</span>
          </Styled.Label>
        </Styled.InputsContainer>
        {onBoardForm === 'VC' ? (
          <Styled.Label>
            {TEXTS.EVE_FLIGHT_NUMBER}
            <ReactInputMask
              mask="aa-9999"
              value={flightCode}
              onChange={e => setFlightCode(e.target.value.toUpperCase())}
            >
              {inputProps => <Styled.Input {...inputProps} type="text" />}
            </ReactInputMask>
          </Styled.Label>
        ) : null}
        {error && <Styled.Error>Erro ao salvar</Styled.Error>}
      </Styled.FormScrollContainer>
      <Styled.ContainerActions>
        <Styled.Button
          onClick={isDisabled || loading ? null : onSubmit}
          loading={loading ? 1 : 0}
          disabled={isDisabled}
        >
          {TEXTS.SAVE}
        </Styled.Button>
      </Styled.ContainerActions>
    </Styled.Container>
  );
};

FormCheckout.propTypes = {
  hasCheckbox: P.bool,
  flightId: P.string,
  onSuccess: P.func,
};

export default FormCheckout;
