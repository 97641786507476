import React from 'react';
import P from 'prop-types';
import * as Styled from './FormCheckout.styles.js';

const Checkbox = ({ checked, onClick }) => {
  return (
    <Styled.CheckBox checked={checked} aria-checked={checked} onClick={onClick}>
      {checked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="20 6 9 17 4 12" />
        </svg>
      )}
    </Styled.CheckBox>
  );
};

Checkbox.propTypes = {
  checked: P.bool,
  onClick: P.func,
};

export default Checkbox;
