// react/third-part modules
import React, { useEffect, useState } from 'react';
import * as Styled from './ValidatorWizard.style';
import { sprintf } from 'sprintf-js';

import CloseX from '@images/close-x.svg';

import { translate as t } from '@utils/translate';

/**
 *
 * @param {array} steps Array of objects (stepKey, isValid, stepMsg)
 * @param {function} onClose Callback for closing event
 */
const ValidatorWizard = ({
  steps = [],
  onClose,
  userName = '',
  onChangeStep,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const USER_NAME = userName ? userName.split(' ')[0] : '';
  const TEXTS = {
    TITLE: sprintf(t('dearUser'), USER_NAME),
    OF: t('of'),
  };

  useEffect(() => {
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      if (!step.isValid) {
        setCurrentStep(i);
        break;
      }
    }
  }, [steps]);

  useEffect(() => {
    if (onChangeStep) onChangeStep(steps[currentStep].stepKey);
  }, [currentStep]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Styled.ValidatorWizardContainer>
      <Styled.CloseButton onClick={handleClose}>
        <img src={CloseX} />
      </Styled.CloseButton>
      {userName && <Styled.Title>{TEXTS.TITLE}</Styled.Title>}
      <Styled.Info>{t(steps[currentStep].stepMsg)}</Styled.Info>
      <Styled.StepsWrapper>
        <Styled.Steps>
          {steps.map((step, index) => {
            return <Styled.Dot key={index} isValid={step.isValid}></Styled.Dot>;
          })}
        </Styled.Steps>
        <span>{`${currentStep + 1} ${TEXTS.OF} 2`}</span>
      </Styled.StepsWrapper>
    </Styled.ValidatorWizardContainer>
  );
};

export default ValidatorWizard;
