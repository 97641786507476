// TODO: REFACTORING
// react/third-part modules
import React, { useRef, useState, useEffect } from 'react';
import Style from './style.module.css';
import { useStore, useSelector } from 'react-redux';
import Slider from 'react-slick';

import PassengersListItem from '@components/PassengersListItem';
import PassengersCollapsedList from '@components/PassengersCollapsedList';
import PrevButton from '@components/PrevButton';
import NextButton from '@components/NextButton';
import PassengersSelector from '@components/PassengersSelector';
// component modules

// contexts/hooks/others
import { setSeats } from '@store/reducers/cart/operations';
import { translate as t } from '@utils/translate';

const PassengersList = ({
  itemsPerPage = 2,
  setFocusSelection,
  showMiniList = true,
}) => {
  const passengers = useSelector(state =>
    state.cart.passengers.filter(passenger => passenger.selected)
  );
  const seats = useSelector(state => state.cart.seats);
  const store = useStore();
  const sliderRef = useRef(null);
  const [isSelectorPanelOpen, setIsSelectorPanelOpen] = useState(false);
  const [showDetailsOfId, setShowDetailsOfId] = useState(undefined);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: itemsPerPage,
    slidesPerRow: 1,
  };
  const TEXTS = {
    BUTTON_LABEL: t('selectPassengers'),
  };

  const handleClickShowAll = () => {
    setIsSelectorPanelOpen(true);
    setFocusSelection(true);
  };

  const handleCloseSelector = () => {
    setIsSelectorPanelOpen(false);
    setFocusSelection(false);
  };

  const updateAvailableSeats = () => {
    let filledSeats = passengers.reduce(
      (count, passenger) => (passenger.selected ? count + 1 : count),
      0
    );
    store.dispatch(setSeats(seats.available, filledSeats, seats.minimum));
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const handleShowPassenger = (id, active) => {
    setIsSelectorPanelOpen(active);
    setFocusSelection(active);
    setShowDetailsOfId(id);
  };

  if (!isSelectorPanelOpen && (passengers.length === 0 || !showMiniList))
    return (
      <div className={Style.emptyList}>
        <button
          onClick={handleClickShowAll}
          className={Style.button}
          data-test="passengersList.button"
        >
          {TEXTS.BUTTON_LABEL}
        </button>
      </div>
    );

  return (
    <>
      {!isSelectorPanelOpen ? (
        <>
          <div className={Style.passengersList}>
            <Slider {...settings} ref={sliderRef}>
              {passengers.map((passenger, index) => (
                <PassengersListItem
                  passenger={passenger}
                  key={index}
                  onClickToShow={handleShowPassenger}
                  index={index}
                  onRemoveItem={updateAvailableSeats}
                />
              ))}
            </Slider>
            <div className={Style.buttonsWrapper}>
              <PrevButton onClick={() => previous()} />
              <NextButton onClick={() => next()} />
            </div>
          </div>

          <div className={Style.passengersCollapsedListWrapper}>
            <PassengersCollapsedList
              handleClickShowAll={handleClickShowAll}
              handleClickShowPassenger={handleShowPassenger}
            />
          </div>
        </>
      ) : (
        <>
          <div className={Style.passengersSelectorWrapper}>
            <PassengersSelector
              showDetailsOfId={showDetailsOfId}
              onCloseDetails={() => handleShowPassenger(undefined, false)}
              intialMode={
                passengers.length === 0 ? 'AVAILABLE-PAX' : 'SELECTED-PAX'
              }
              onClose={handleCloseSelector}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PassengersList;
