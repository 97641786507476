/**
 * Returns the most specific error message
 *
 * @param {object} error Request error object
 * @return string
 */
const getRequestErrorMessage = error => {
  if (!error) return '';

  let message = '';
  if (error.errors && error.errors.length > 0) {
    message = error.errors[0]['text'] || error.msg;
  } else message = error.msg;

  return message;
};

export { getRequestErrorMessage };
