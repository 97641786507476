// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Style from './style.module.css';
import { translate as t } from '@utils/translate';
import aero_commander from '@images/aero_commander.png';

import ErrorAlert from '@components/ErrorAlert';
import { gtmDispatcher } from '@utils/gtm.helper';

const PaymentConfirmation = ({
  closeSuccess,
  closeFailure,
  userName,
  success,
  error,
}) => {
  const [failureMessage, setFailureMessage] = useState('');
  const TEXTS = {
    ERROR_TITLE: 'Oopss...',
    ERROR_SUBTITLE: t('somethingWrong'),
    ERROR_ADVICE: t('seeErrorAndTryAgain'),
    ERROR_BUTTON: t('backToPayment'),
    SUCCESS_TITLE: t('confirmationTitle'),
    SUCCESS_SUBTITLE: t('congrats'),
    RETURN_BUTTON: t('returnHomeText'),
  };

  useEffect(() => {
    setFailureMessage(error);
  }, [error]);

  useEffect(() => {
    if (success) {
      gtmDispatcher('booking', {
        event_category: 'purchase',
        event_action: 'payment',
        event_label: 'The user pays for the purchase',
      });
    }
  }, [success]);

  return (
    <>
      {success ? (
        <div className={Style.modalSuccess}>
          <div className={Style.content}>
            <div className={Style.titleContent}>
              <h1 className={Style.title}>{TEXTS.SUCCESS_TITLE}!</h1>
              <span className={Style.description}>
                {TEXTS.SUCCESS_SUBTITLE}!
              </span>
            </div>
            <button onClick={closeSuccess} className={Style.button}>
              {TEXTS.RETURN_BUTTON}
            </button>
          </div>
          <img
            src={aero_commander}
            alt={t('aircraft')}
            className={Style.image}
          />
        </div>
      ) : (
        <ErrorAlert
          title={TEXTS.ERROR_TITLE}
          subtitle={TEXTS.ERROR_SUBTITLE}
          description={TEXTS.ERROR_ADVICE}
          errorMsg={failureMessage}
          buttonLabel={TEXTS.ERROR_BUTTON}
          onClick={closeFailure}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userName: state.cart.userData,
  error: state.errors['payment'],
});

export default connect(mapStateToProps)(PaymentConfirmation);
