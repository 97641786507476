// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const NextButton = ({ disabled, onClick }) => {
  return (
    <button disabled={disabled} className={Style.nextButton} onClick={onClick}>
      <div className={Style.nextArrow}></div>
    </button>
  );
};

export default NextButton;
