// react/third-part modules
import React, { useState, useEffect, useRef } from 'react';
import P from 'prop-types';
import Style from './style.module.css';

// Store
import { useSelector, useStore } from 'react-redux';
import {
  toggleSelectedPassenger,
  setSeats,
  putPassenger,
} from '@store/reducers/cart/operations';
import AddUser from '@images/add-user.png';

// Assets
import CloseX from '@images/close-x.svg';

// Components
import PassengersListItem from '@components/PassengersListItem';
import SinglePassengerViewer from './SinglePassengerViewer';
import NewPassengerForm from './NewPassengerForm';
import Dialog from '@components/Dialog';
import FormPassenger from '@components/EVE/FormPassenger/FormPassenger';

// Utils
import { translate as t } from '@utils/translate';
import id from 'date-fns/esm/locale/id/index.js';

const Close = ({ onClick }) => {
  const TEXTS = {
    CLOSE_SPAN: t('close'),
  };
  return (
    <div className={Style.closeButtonWrapper} onClick={onClick}>
      <span>{TEXTS.CLOSE_SPAN}</span>
      <img src={CloseX} />
    </div>
  );
};

Close.propTypes = {
  onClick: P.func,
};

const PassengersSelector = ({
  showDetailsOfId,
  onCloseDetails,
  intialMode,
  onClose,
}) => {
  const TEXTS = {
    CONTACTS_INPUT: t('contacts'),
    FULL_SEATS: t('fullSeats'),
    NEW_PASSENGER: t('addNewPassenger'),
    SAVE_ALTERATIONS: t('saveButton'),
    SHOW_ALL: t('showAll'),
    REQUIRED_DATA: t('requiredDataEve'),
  };

  const MODES = {
    SELECTED_PAX: 'SELECTED-PAX',
    AVAILABLE_PAX: 'AVAILABLE-PAX',
    ADD_PAX: 'ADD-PAX',
    DETAIL_PAX: 'DETAIL-PAX',
  };
  const passengers = useSelector(state => state.cart.passengers);
  const seats = useSelector(state => state.cart.seats);
  const cartData = useSelector(state => state.cart.cartData);
  const store = useStore();

  const FLIGHT_EVENTS = {
    EVE_BH:
      cartData.products[0].data.flight.flightSections.name ===
      'Eve BH Experience',
  };

  const passengersRef = useRef([]);
  const [availableSeats, setAvailableSeats] = useState(seats.available);
  const [minimumSeats, setMinimumSeats] = useState(seats.minimum);
  const [showEVEModal, setShowEVEModal] = useState({
    state: false,
    passenger: null,
  });
  const [currentMode, setCurrentMode] = useState(() => {
    if (!passengers.length) return MODES.ADD_PAX;

    return intialMode || MODES.SELECTED_PAX;
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [passengerDetails, setPassengerDetails] = useState(null);

  useEffect(() => {
    let ids = [];
    passengers.forEach(passenger => {
      // get the correct passenger when is to show him alone
      if (passenger.id === showDetailsOfId) {
        setPassengerDetails(passenger);
      }

      if (passenger['selected'] === true) {
        ids.push(passenger.id);
        return true;
      }
    });
    setSelectedIds(ids);
    updateAvailableSeats();
  }, [passengers]);

  const handleSelectItem = (id, selected) => {
    let ids = [...selectedIds];

    const addNewId = () => {
      if (!ids.includes(id)) {
        ids.push(id);
        setSelectedIds([...ids]);
      }
    };

    if (selected) {
      addNewId();
    } else {
      let index = ids.indexOf(id);
      if (index !== -1) {
        ids.splice(index, 1);
        setSelectedIds(ids);
      }
    }
    updateAvailableSeats();
  };

  const updateAvailableSeats = () => {
    let filledSeats = passengers.reduce(
      (count, passenger) => (passenger.selected ? count + 1 : count),
      0
    );
    store.dispatch(setSeats(seats.available, filledSeats, seats.minimum));
    setAvailableSeats(seats.available - filledSeats);
  };

  const confirmSelection = () => {
    passengers.forEach((passenger, index) => {
      store.dispatch(
        toggleSelectedPassenger(
          passenger.id,
          selectedIds.includes(passenger.id)
        )
      );
    });
    handleSelectMode(MODES.SELECTED_PAX);
    updateAvailableSeats();
    if (onClose) onClose();
  };

  const handleSelectMode = mode => {
    setCurrentMode(mode);
  };

  const handleCloseDetails = () => {
    setCurrentMode(MODES.AVAILABLE_PAX);
    setPassengerDetails(null);
    if (onCloseDetails) onCloseDetails();
  };

  const handleWithSelect = (passenger, value) => {
    if (FLIGHT_EVENTS.EVE_BH) {
      if (!passenger.weight) {
        passengersRef.current[passenger.id].setSelected(false);
        setShowEVEModal({ state: true, passenger: passenger });
      } else {
        handleSelectItem(passenger.id, value);
      }
    } else {
      handleSelectItem(passenger.id, value);
    }
  };

  const submitEVEform = (data, passenger) => {
    handleSelectItem(passenger.id, true);
    const updatedPassenger = { ...passenger, ...data };
    setShowEVEModal({ state: false, passenger: null });

    // TODO fazer a req. e só salvar no estado global quando a requisição for um sucesso
    store
      .dispatch(putPassenger(updatedPassenger))
      .then(() => {
        passengersRef.current[passenger.id].setSelected(true);
        handleSelectItem(passenger.id, true);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const handleClose = forceAvailablePax => {
    const countSelectedPassengers = passengers.reduce((count, passenger) => {
      return (count += passenger.selected ? 1 : 0);
    }, 0);

    // if in AVAILABLE_PAX mode and at least one was selected, go to the SELECTED_PAX
    if (currentMode === MODES.AVAILABLE_PAX && countSelectedPassengers > 0)
      handleSelectMode(MODES.SELECTED_PAX);
    // if in ADD-PAX mode and there is at least one passenger available, go to the AVAILABLE_PAX
    else if (
      currentMode === MODES.ADD_PAX &&
      (passengers.length > 0 || forceAvailablePax)
    )
      handleSelectMode(MODES.AVAILABLE_PAX);
    else {
      // else, close everything
      handleSelectMode(MODES.SELECTED_PAX);
      if (onClose) onClose();
    }

    // stop showing single passenger
    if (onCloseDetails && passengerDetails) onCloseDetails();
  };

  // showing single passenger
  if (showDetailsOfId !== undefined) {
    return (
      passengerDetails && (
        <div className={Style.passengersSelector}>
          <Close onClick={handleClose} />

          <SinglePassengerViewer
            passengers={passengers}
            initialId={showDetailsOfId}
            onClose={handleCloseDetails}
          />
        </div>
      )
    );
  }

  useEffect(() => {
    setAvailableSeats(seats.available);
  }, [seats]);

  // showing new passenger form
  if (currentMode === MODES.ADD_PAX) {
    return (
      <div className={Style.passengersSelector}>
        <Close onClick={handleClose} />

        <NewPassengerForm onClose={handleClose} />
      </div>
    );
  }

  // showing selected or available passengers list
  return (
    <>
      <div className={Style.passengersSelector}>
        <Close onClick={handleClose} />

        {currentMode === MODES.AVAILABLE_PAX && (
          <div className={Style.passengersSelectionHeader}>
            <h3 className={Style.title}>
              {TEXTS.CONTACTS_INPUT}{' '}
              <span
                className={[
                  Style.counter,
                  selectedIds.length === availableSeats ? Style.filled : '',
                  selectedIds.length < minimumSeats ? Style.pending : '',
                ].join(' ')}
              >{`(${selectedIds.length}/${availableSeats})`}</span>
            </h3>
          </div>
        )}

        <div className={Style.passengersSelectorList}>
          {selectedIds.length === 0 && currentMode === MODES.SELECTED_PAX && (
            <div className={Style.warning}>Nenhum passageiro selecionado.</div>
          )}
          {passengers.length === 0 && currentMode === MODES.AVAILABLE_PAX && (
            <div className={Style.warning}>Nenhum passageiro cadastrado.</div>
          )}
          {passengers.map((passenger, index) => {
            if (currentMode === MODES.SELECTED_PAX && !passenger.selected)
              return null;

            return (
              <PassengersListItem
                passenger={passenger}
                ref={passengersRef}
                key={passenger.id}
                index={index}
                isSelectable={currentMode === MODES.AVAILABLE_PAX}
                canSelect={
                  availableSeats > selectedIds.length ||
                  selectedIds.includes(passenger.id)
                }
                onRemoveItem={id => handleSelectItem(id, false)}
                onClickToShow={() => {}}
                onToggleSelection={(_, value) => {
                  handleWithSelect(passenger, value);
                }}
              />
            );
          })}
        </div>

        {[MODES.SELECTED_PAX, MODES.AVAILABLE_PAX].includes(currentMode) &&
          !availableSeats && (
            <div className={Style.availableSeats}>
              <span>{TEXTS.FULL_SEATS}</span>
            </div>
          )}

        <div className={Style.actions}>
          {currentMode === MODES.AVAILABLE_PAX ? (
            <>
              <div
                className={Style.addPassengersButton}
                onClick={() => handleSelectMode(MODES.ADD_PAX)}
              >
                <span>{TEXTS.NEW_PASSENGER}</span>
                <img src={AddUser} />
              </div>

              <button
                data-test="passengerSelector.save"
                onClick={confirmSelection}
                className={Style.button}
              >
                {TEXTS.SAVE_ALTERATIONS}
              </button>
            </>
          ) : (
            <div className={Style.actionRow}>
              <div
                className={[
                  Style.addPassengersButton,
                  availableSeats > 0 ? '' : Style.disabled,
                ].join(' ')}
                onClick={() => handleSelectMode(MODES.AVAILABLE_PAX)}
              >
                <span>{TEXTS.SHOW_ALL}</span>
              </div>

              <div
                className={[
                  Style.addPassengersButton,
                  availableSeats > 0 ? '' : Style.disabled,
                ].join(' ')}
                onClick={() => handleSelectMode(MODES.ADD_PAX)}
              >
                <span>{TEXTS.NEW_PASSENGER}</span>
                <img src={AddUser} />
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog open={showEVEModal.state} setOpen={setShowEVEModal}>
        <FormPassenger
          passenger={showEVEModal.passenger}
          info={TEXTS.REQUIRED_DATA}
          onSubmit={(data, passenger) => {
            submitEVEform(data, passenger);
          }}
        />
      </Dialog>
    </>
  );
};

PassengersSelector.propTypes = {
  showDetailsOfId: P.any,
  onCloseDetails: P.func,
  intialMode: P.string,
  onClose: P.func,
};

export default PassengersSelector;
