// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';
import PathCard from '@components/PathCard';
import Slider from 'react-slick';
import { sprintf } from 'sprintf-js';

import { translate as t } from '@utils/translate';

const TripSectionModal = ({ flights, closeModal }) => {
  const TEXTS = {
    PATH: t('segmentNofN'),
  };
  const [cardPosition, setCardPosition] = useState('');
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCardPosition(next),
  };
  const slider = React.createRef();

  const previous = () => {
    slider.current.slickPrev();
  };

  const next = () => {
    slider.current.slickNext();
  };

  return (
    <div className={Style.modalContainer}>
      <div className={Style.modal}>
        <div className={Style.modalContent}>
          <Slider {...settings} ref={slider}>
            {flights.map((flight, index) => (
              <div>
                <span className={Style.pathText}>
                  {sprintf(TEXTS.PATH, index + 1, flights.length)}
                </span>

                <PathCard
                  route={flight}
                  modal={true}
                  flightsLenght={flights.length}
                  cardPosition={cardPosition}
                  closeModal={closeModal}
                  previous={() => previous()}
                  next={() => next()}
                />
              </div>
            ))}
          </Slider>
        </div>
        <span className={Style.minimizeButton} onClick={closeModal}>
          <i>▲</i>
          <span className={Style.minimizeText}>Minimizar</span>
        </span>
      </div>
    </div>
  );
};

export default TripSectionModal;
