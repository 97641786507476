import React, { useState, useEffect } from 'react';
import P from 'prop-types';
import * as Styled from './NewPassengerForm.style';
import InputMask from 'react-input-mask';

// components
import CreditCardInput from '@components/CreditCardInput';

// store
import { useDispatch, useSelector } from 'react-redux';
import { addPassenger } from '@store/reducers/cart/operations';

// utils
import { translate as t } from '@utils/translate';

const NewPassengerForm = ({
  onSave = () => {},
  onCancel = () => {},
  onClose,
}) => {
  const dispatch = useDispatch();
  const cartData = useSelector(state => state.cart.cartData);

  const [program, setProgram] = useState({ eveBH: false });
  const [isFormValid, setIsFormValid] = useState(false);
  const [checked, setChecked] = useState(false);
  const [formData, setFormData] = useState({
    reduced_mobility: 0,
  });
  const [mask, setMask] = useState({
    phoneNumber: '+99 (99) 9 9999-9999',
    identity: null,
    date: '99/99/9999',
  });

  const maskProps = {
    maskChar: '',
    alwaysShowMask: false,
  };

  const verifyProgramsExist = () => {
    const flightSections = cartData.products[0].data.flight.flightSections;

    if (flightSections.name === 'Eve BH Experience') {
      setProgram(prevState => ({ ...prevState, eveBH: true }));
    }
  };

  useEffect(() => {
    verifyProgramsExist();
  }, []);

  const validateForm = () => {
    const requiredInputs = 8;
    const values = Object.values(formData);
    let isFormValid = false;

    if (values.length >= requiredInputs) {
      isFormValid = true;

      values.forEach(value => {
        if (typeof value !== 'number') {
          if (!value || value.trim() === '') {
            isFormValid = false;
          }
        }
      });
    }

    if (!formData.weight && program.eveBH) isFormValid = false;

    setIsFormValid(isFormValid);
  };

  const handleChange = e => {
    let { name, value } = e.target;

    setFormData(oldState => ({
      ...oldState,
      reduced_mobility: 0,
      [name]: name !== 'reduced_mobility' ? (!!value ? value : null) : value,
    }));

    validateForm();
  };

  const formatToSave = (type, value) => {
    let formatedValue = '';
    try {
      switch (type) {
        case 'phone':
          formatedValue = value.replace(/^(\+)|\D/g, '$1');
          return formatedValue;

        case 'date':
          formatedValue = value.split('/');
          formatedValue = `${formatedValue[2]}-${formatedValue[1]}-${formatedValue[0]}`;
          return formatedValue;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
      return '';
    }

    return '';
  };

  const save = () => {
    const finalFormData = {
      ...formData,
      phoneNumber: formatToSave('phone', formData.phoneNumber),
      emergencyPhoneNumber: formatToSave(
        'phone',
        formData.emergencyPhoneNumber
      ),
      birthdayDate: formatToSave('date', formData.birthdayDate),
      documentType: '',
      documentComplement: '',
      gender: '',
      phoneType: '',
      emergencyContactName: '',
    };
    dispatch(addPassenger(finalFormData)).then(res => {
      onSave();
      onClose(true);
    });
  };

  const cancel = () => {
    onCancel();
    onClose();
  };

  const TEXTS = {
    NEW_PASSENGER: t('addNewPassenger'),
    NAME_INPUT: t('name'),
    LAST_NAME: t('lastName'),
    EMAIL_INPUT: t('email'),
    INPUT_DOCUMENT: t('documentInput'),
    BIRTH_DATE: t('birthInput'),
    PHONE_NUMBER: t('phoneInput'),
    EMERGENCY_PHONE: t('emergencyPhone'),
    CANCEL_BUTTON: t('cancel'),
    SAVE_BUTTON: t('saveButton'),
    WEIGHT: t('weight'),
    INFO_WEIGHT: t('infoWeight'),
    REDUCED_MOBILITY: t('reducedMobility'),
  };
  return (
    <Styled.FormWrapper>
      <Styled.Title>{TEXTS.NEW_PASSENGER}</Styled.Title>
      <Styled.Form>
        <Styled.InputContainer>
          <Styled.InputRow>
            <Styled.InputColumn>
              <CreditCardInput>
                <InputMask
                  onChange={evt => handleChange(evt)}
                  onBlur={validateForm}
                  name="firstName"
                  type="text"
                  tabIndex="1"
                  autoFocus
                  placeholder=" "
                />
                <span>{TEXTS.NAME_INPUT}</span>
              </CreditCardInput>
            </Styled.InputColumn>

            <Styled.InputColumn>
              <CreditCardInput>
                <InputMask
                  onChange={evt => handleChange(evt)}
                  onBlur={validateForm}
                  name="lastName"
                  type="text"
                  tabIndex="2"
                  placeholder=" "
                />
                <span>{TEXTS.LAST_NAME}</span>
              </CreditCardInput>
            </Styled.InputColumn>
          </Styled.InputRow>

          <Styled.InputRow>
            <CreditCardInput>
              <InputMask
                onChange={evt => handleChange(evt)}
                onBlur={validateForm}
                name="emailAddress"
                type="email"
                tabIndex="3"
                placeholder=" "
              />
              <span>{TEXTS.EMAIL_INPUT}</span>
            </CreditCardInput>
          </Styled.InputRow>

          <Styled.InputRow>
            <Styled.InputColumn>
              <CreditCardInput>
                <InputMask
                  onChange={evt => handleChange(evt)}
                  onBlur={validateForm}
                  name="identityDocument"
                  type="text"
                  placeholder=" "
                  tabIndex="4"
                  value={formData.identityDocument || ''}
                  maxLength="16"
                />
                <span>{TEXTS.INPUT_DOCUMENT}</span>
              </CreditCardInput>
            </Styled.InputColumn>

            <Styled.InputColumn>
              <CreditCardInput>
                <InputMask
                  {...maskProps}
                  mask={mask.date}
                  onChange={evt => handleChange(evt)}
                  onBlur={validateForm}
                  name="birthdayDate"
                  type="text"
                  tabIndex="5"
                  placeholder=" "
                />
                <span>{TEXTS.BIRTH_DATE}</span>
              </CreditCardInput>
            </Styled.InputColumn>
          </Styled.InputRow>

          <Styled.InputRow>
            <Styled.InputColumn>
              <CreditCardInput>
                <InputMask
                  {...maskProps}
                  mask={mask.phoneNumber}
                  onChange={evt => handleChange(evt)}
                  onBlur={validateForm}
                  name="phoneNumber"
                  type="text"
                  tabIndex="6"
                  placeholder=" "
                />
                <span>{TEXTS.PHONE_NUMBER}</span>
              </CreditCardInput>
            </Styled.InputColumn>

            <Styled.InputColumn>
              <CreditCardInput>
                <InputMask
                  {...maskProps}
                  mask={mask.phoneNumber}
                  onChange={evt => handleChange(evt)}
                  onBlur={validateForm}
                  name="emergencyPhoneNumber"
                  type="text"
                  tabIndex="7"
                  placeholder=" "
                />
                <span>{TEXTS.EMERGENCY_PHONE}</span>
              </CreditCardInput>
            </Styled.InputColumn>
          </Styled.InputRow>
        </Styled.InputContainer>

        {/* REFERENTE AO PROGRAMA EVE - BH - 2022 */}
        {program.eveBH && (
          <>
            <Styled.Line />

            <Styled.Info>{TEXTS.INFO_WEIGHT}</Styled.Info>

            <Styled.InputColumn>
              <CreditCardInput>
                <InputMask
                  // alwaysShowMask
                  maskChar=" "
                  mask="999"
                  onChange={evt => {
                    handleChange({
                      ...evt,
                      target: {
                        ...evt.target,
                        name: evt.target.name,
                        value: Number(evt.target.value.replace('kg', '')),
                      },
                    });
                  }}
                  onBlur={validateForm}
                  name="weight"
                  type="text"
                  tabIndex="8"
                />
                <span>{TEXTS.WEIGHT}</span>
              </CreditCardInput>
              <span className="suffix">KG</span>
            </Styled.InputColumn>

            <Styled.Line />

            <Styled.Label>
              <input
                type="checkbox"
                onChange={evt => {
                  handleChange({
                    ...evt,
                    target: {
                      ...evt.target,
                      name: evt.target.name,
                      value: !checked ? 1 : 0,
                    },
                  });

                  setChecked(!checked);
                }}
                name="reduced_mobility"
                value={checked}
              />
              {TEXTS.REDUCED_MOBILITY}
            </Styled.Label>
          </>
        )}

        <Styled.BtnContainer>
          <Styled.Button onClick={cancel} theme="default" type="button">
            {TEXTS.CANCEL_BUTTON}
          </Styled.Button>
          <Styled.Button
            theme={isFormValid ? 'active' : 'disabled'}
            type="button"
            onClick={save}
          >
            {TEXTS.SAVE_BUTTON}
          </Styled.Button>
        </Styled.BtnContainer>
      </Styled.Form>
    </Styled.FormWrapper>
  );
};

NewPassengerForm.propTypes = {
  onSave: P.func,
  onCancel: P.func,
  onClose: P.func,
};

export default NewPassengerForm;
