import React, { useState } from 'react';
import P from 'prop-types';
import * as Styled from './FormPassenger.styled';
import InputMask from 'react-input-mask';
import { MdInfo } from 'react-icons/md';

// components
import CreditCardInput from '@components/CreditCardInput';
import Checkbox from '@components/Checkbox';

// utils
import { translate as t } from '@utils/translate';

const FormPassenger = ({ passenger, info, onSubmit }) => {
  const [data, setData] = useState({ weight: '', reduced_mobility: 0 });
  const [errorMsg, setErrorMsg] = useState('');

  const TEXTS = {
    CHECKBOX_LABEL: t('eveBhCheckbox'),
    SAVE_BUTTON: t('saveButton'),
    WEIGHT: t('weight'),
    INFO_WEIGHT: t('infoWeight'),
    REDUCED_MOBILITY: t('reducedMobility'),
    REQUIRED_WEIGHT: t('eveWeightError'),
    REDUCED_MOBILITY: t('reducedMobility'),
  };

  const handleValidation = e => {
    e.preventDefault();
    if (!data.weight) {
      setErrorMsg(TEXTS.REQUIRED_WEIGHT);
      setTimeout(() => {
        setErrorMsg('');
      }, 3000);
      return;
    }
    onSubmit(data, passenger);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const dataForm = { ...data };
    dataForm[name] = value;
    setData(dataForm);
  };

  return (
    <Styled.Container>
      <Styled.Title>
        {passenger?.namePrefix} {passenger?.firstName}
      </Styled.Title>
      <Styled.Subtitle style={{ position: 'relative', height: 30 }}>
        {/* <Info /> */}
        <MdInfo
          size="30px"
          color="#01bea4"
          className="icon"
          style={{ position: 'absolute', right: 0 }}
        />
        <span className="popupInfo">{info}</span>
      </Styled.Subtitle>
      <Styled.Form>
        <Styled.InputContainer>
          <CreditCardInput>
            <InputMask
              // alwaysShowMask
              maskChar=" "
              mask="999"
              onChange={evt => {
                handleChange({
                  ...evt,
                  target: {
                    ...evt.target,
                    name: evt.target.name,
                    value: Number(evt.target.value.replace('kg', '')),
                  },
                });
              }}
              // onBlur={validateForm}
              name="weight"
              type="text"
              tabIndex="8"
            />
            <span>{TEXTS.WEIGHT}</span>
          </CreditCardInput>
          <span className="suffix">KG</span>
        </Styled.InputContainer>
        <Styled.Label>
          <Checkbox
            selected={data.reduced_mobility === 1 ? true : false}
            onClick={() => {
              setData({
                ...data,
                reduced_mobility: data.reduced_mobility === 1 ? 0 : 1,
              });
            }}
          />
          <span className="checkboxLabel">{TEXTS.REDUCED_MOBILITY}</span>
        </Styled.Label>
        <Styled.Button theme={'active'} onClick={e => handleValidation(e)}>
          {TEXTS.SAVE_BUTTON}
        </Styled.Button>
        {errorMsg && <Styled.ErrorMSG>{errorMsg}</Styled.ErrorMSG>}
      </Styled.Form>
    </Styled.Container>
  );
};

FormPassenger.propTypes = {
  passenger: P.object,
  info: P.string,
  onSubmit: P.func,
};

export default FormPassenger;
