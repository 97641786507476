// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import { useStore } from 'react-redux';
import { updateUserData } from '@store/reducers/cart/operations';
import SharedForm from './SharedForm';
import { getUser, handleUpdateUser } from '@services/auth';
import ProfileForm from '@components/ProfileForm';

import * as Styled from './EditUserModal.styles';

import { translate as t } from '@utils/translate';

const EditUserModal = ({ userData, closeModal, isShared }) => {
  const store = useStore();
  const TEXTS = {
    YOUR_DATA: t('yourData'),
    COMPLETE_YOUR_INFO: t('fullfillInformation'),
  };

  const [failData, setFailData] = useState(false);
  const [user, setUser] = useState(getUser());
  const [status, setStatus] = useState(false);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  function verifyUser() {
    if (!user.documentTypeId || !user.identityDocument || !user.birthdayDate)
      setFailData(true);
  }

  function nextForm() {
    setStatus(true);
  }

  const handleSubmitProfile = data => {
    if (!data.birthdayDate || !data.documentTypeId || !data.identityDocument) {
      return;
    }
    const fields = [
      'fullName',
      'emailAddress',
      'documentTypeId',
      'birthdayDate',
      'mobileNumber',
      'emergencyPhoneNumber',
      'identityDocument',
    ];
    const new_user = Object.assign({}, user);
    fields.forEach(field => {
      new_user[field] = data[field];
    });
    if (new_user['birthdayDate']) {
      const parts = new_user['birthdayDate'].split('/');
      new_user['birthdayDate'] = parts[2] + '-' + parts[1] + '-' + parts[0];
    }

    if (data['birthCountry']) {
      new_user['countryOfBirth'] = {
        iso2Code: data['birthCountry'],
      };
    }

    delete new_user['updateDatetime'];
    delete new_user['createDatetime'];
    setUser(new_user);
    handleUpdateUser(setLoading, setErrorMsg, new_user);
  };

  const handleChangeProfile = () => {
    setActiveSubmit(true);
  };

  function handleUpdateData(userName, userDocument) {
    store.dispatch(
      updateUserData({
        fullname: userName,
        identityDocument: userDocument,
      })
    );
    if (failData) {
      nextForm();
    } else {
      closeModal();
    }
  }

  useEffect(() => {
    verifyUser();
  }, []);

  return (
    <Styled.Modal>
      {isShared && (
        <SharedForm
          {...userData}
          show={status}
          failData={failData}
          handleUpdateData={(userName, document) =>
            handleUpdateData(userName, document)
          }
        />
      )}
      <Styled.Container show={status} shared={isShared}>
        <Styled.Title>{TEXTS.YOUR_DATA}</Styled.Title>
        <Styled.SubTitle>{TEXTS.COMPLETE_YOUR_INFO}</Styled.SubTitle>
        <ProfileForm
          failData={failData}
          onSubmit={handleSubmitProfile}
          onChange={handleChangeProfile}
          loading={loading}
          activeSubmit={activeSubmit}
          errorMsg={errorMsg}
        />
      </Styled.Container>
    </Styled.Modal>
  );
};
export default EditUserModal;
