// TODO: REFACTORING
// react/third-part modules
import React, { useRef } from 'react';
import Style from './style.module.css';
import Slider from 'react-slick';
import { useStore } from 'react-redux';
import { toggleSelectedPassenger } from '@store/reducers/cart/operations';

// component modules
import PrevButton from '@components/PrevButton';
import NextButton from '@components/NextButton';

// contexts/hooks/others
import Phone from '@images/phone.svg';
import { translate as t } from '@utils/translate';

const SinglePassengerViewer = ({ passengers, initialId, onClose }) => {
  const selectedPassengers = passengers.filter(passenger => passenger.selected);
  const sliderRef = useRef(null);
  const store = useStore();

  const getInitialIndex = () => {
    let index = 0;
    selectedPassengers.forEach((passenger, idx) => {
      if (passenger.id === initialId) {
        index = idx;
      }
    });
    return index;
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: getInitialIndex(),
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const handleRemovePassenger = id => {
    const closeAfterRemove = selectedPassengers.length === 1;
    store.dispatch(toggleSelectedPassenger(id, false));

    if (closeAfterRemove && onClose) onClose();
  };

  const TEXTS = {
    REMOVE_PASSENGER: t('removePassenger'),
  };
  return (
    <div className={Style.singlePassengerContainer}>
      <Slider {...settings} ref={sliderRef}>
        {selectedPassengers.map((passenger, index) => (
          <div key={index}>
            <div className={Style.singlePassengerWrapper}>
              <div className={Style.singlePassengerPicture}>
                <img src={passenger.imageUrl} />
              </div>

              <div className={Style.singlePassengerInfo}>
                <div className={Style.singlePassengerHeader}>
                  <span className={Style.singlePassengerName}>
                    {`${passenger.firstName} ${passenger.lastName}`}
                  </span>
                  <img className={Style.singlePassengerStar} src={''} />
                </div>

                <div className={Style.singlePassengerDetailsWrapper}>
                  <div className={Style.singlePassengerPhone}>
                    <img src={Phone} />
                    <span>
                      {passenger.phoneNumber
                        ? passenger.phoneNumber.replace(
                            /(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/,
                            '$1 ($2) $3 $4-$5'
                          )
                        : ''}
                    </span>
                  </div>
                  <div className={Style.singlePassengerPhone}>
                    <img src={Phone} />
                    <span>
                      {passenger.emergencyPhoneNumber
                        ? passenger.emergencyPhoneNumber.replace(
                            /(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/,
                            '$1 ($2) $3 $4-$5'
                          )
                        : ''}
                    </span>
                  </div>
                  <div className={Style.singlePassengerRemove}>
                    <span onClick={() => handleRemovePassenger(passenger.id)}>
                      {TEXTS.REMOVE_PASSENGER}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className={Style.singlePassengerActions}>
        <div></div>
        <div className={Style.buttonsWrapper}>
          <PrevButton onClick={() => previous()} />
          <NextButton onClick={() => next()} />
        </div>
      </div>
    </div>
  );
};

export default SinglePassengerViewer;
