// react/third-part modules
import React, { useEffect, useState } from 'react';
import Style from './style.module.css';
import { navigate } from 'gatsby';

// component modules
import TabSection from '@components/TabSection';
import TripSection from '@components/TripSection';
import InvoicingManager from '@components/InvoicingManager';
import CreditCardSelector from '@components/CreditCardSelector';
import InstallmentsSelector from '@components/InstallmentsSelector';
import PassengersManager from '@components/PassengersManager';
import CreditCardCreator from '@components/CreditCardCreator';
import Loader from '@components/Loader';
import Dialog from '@components/Dialog';
import FormCheckout from '@components/EVE/FormCheckout';

import ErrorAlert from '@components/ErrorAlert';
import PaymentConfirmation from '@components/PaymentConfirmation';
// contexts/hooks/others
import { useParams } from '@reach/router';
import { useSelector, useStore } from 'react-redux';
import {
  fetchCartData,
  setCartData,
  fetchPaymentMethods,
  setUserData,
  setPaymentMethodsList,
  fetchPassengersList,
  setCardBrands,
  setPassengersList,
} from '@store/reducers/cart/operations';
import { execPayment } from '@services/payment';
import {
  formatCartForPayment,
  formatInvoiceForPayment,
  formatPassengersForPayment,
  formatPaymentMethodForPayment,
} from '@utils/paymentHelper';
import { getUser, getAllDataUser } from '@services/auth';
import { getFlightEVEInfo } from '@services/eve';
import { getAddresses } from '@store/reducers/address/operations';
import { translate as t } from '@utils/translate';
import useErrorHandler from '@utils/useErrorHandler';
import { getRequestErrorMessage } from '@utils/errorsHelper';
import ValidatorWizard from '@components/ValidatorWizard';

const RESULT = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const Checkout = () => {
  const store = useStore();
  const selectedPassengers = useSelector(
    state => state.cart.selectedPassengers
  );
  const cart = useSelector(state => state.cart);
  const pParams = useParams();
  const setError = useErrorHandler();
  const minimumSeats = useSelector(state => state.cart.seats.minimum);

  // states for component's UIs
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [hasBackdrop, setHasBackdrop] = useState(false);
  const [isCreatingCard, setIsCreatingCard] = useState(false);
  const [isSelectingPassengers, setIsSelectingPassengers] = useState(false);
  const [tabSectionHasFocus, setTabSectionHasFocus] = useState(false);
  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [showEVEDialog, setShowEVEDialog] = useState(false);
  const [hasCheckbox, setHasCheckbox] = useState(false);
  const [flightId, setFlightId] = useState('');
  const [alreadyShow, setAlreadyShow] = useState(false);
  // required validations
  const [showValidator, setShowValidator] = useState(false);
  const [validationSteps, setValidationSteps] = useState([]);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [paymentConfirm, setPaymentConfirm] = useState(null);
  const TEXTS = {
    LOADING_MSG_PAYMENT: t('processingPayment'),
    LOADING_MSG_INIT: t('loading'),
    VALIDATOR_INVOICING: t('validatorMsgInvoicing'),
    VALIDATOR_PAX: t('validatorMsgPax'),
    ERROR_SUBTITLE: t('invalidCart'),
    ERROR_ADVICE: t('timeExpired'),
    ERROR_BUTTON: t('backToHomepage'),
    ERROR_MSG: t('invalidCartAfterTime'),
    INVOINCING_DATA: t('invoicingData'),
    PASSENGERS: t('passengers'),
    ADD_NEW_CARD: t('addNewCard'),
    NAME_LABEL: t('fullName'),
    EDIT_BUTTON: t('edit'),
    NO_ADDRESS: t('addressNotInformed'),
  };

  const user = getUser();
  const allDataUser = getAllDataUser();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    (async () => {
      const products = cart?.cartData?.products;
      const firstProduct =
        typeof products === 'object' ? products[0]?.data?.flight : null;

      if (firstProduct && !alreadyShow) {
        setFlightId(firstProduct.id);
        const {
          data: { info },
        } = await getFlightEVEInfo(firstProduct.id, allDataUser.id);

        if (
          firstProduct.flightSections.name === 'Eve BH Experience' &&
          info.length === 0
        ) {
          setShowEVEDialog(true);
          setHasCheckbox(true);
          setAlreadyShow(true);
        }
      }
    })();
  }, [cart]);

  const getData = async () => {
    setIsLoading(true);
    setLoadingMsg(TEXTS.LOADING_MSG_INIT);

    const cartResponse = await fetchData(fetchCartData, pParams.token);
    const paymentMethodsResponse = await fetchData(fetchPaymentMethods);
    const passengersResponse = await fetchData(fetchPassengersList);

    // cart
    if (cartResponse.result.status === 0)
      store.dispatch(setCartData({ ...cartResponse.cart }));
    else {
      setShowError(true);
    }

    // address
    store.dispatch(getAddresses());

    // payment methods
    if (paymentMethodsResponse.result.status === 0) {
      store.dispatch(setCardBrands(paymentMethodsResponse.cardBrands));
      store.dispatch(
        setPaymentMethodsList(paymentMethodsResponse.paymentMethods)
      );
    }

    // passengers
    if (passengersResponse.result.status === 0)
      store.dispatch(setPassengersList([...passengersResponse.passengers]));

    // user
    if (user) {
      store.dispatch(
        setUserData({
          value: {
            ...user,
            fullname: user.fullName,
          },
        })
      );
    }

    setLoadingMsg('');
    setIsLoading(false);
  };

  const fetchData = (fetchMethod, params) => {
    return new Promise(resolve => {
      store
        .dispatch(fetchMethod(params))
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  useEffect(() => {
    setValidationSteps({
      isValid: false,
      steps: [
        {
          stepKey: 'invoicing',
          isValid: false,
          stepMsg: TEXTS.VALIDATOR_INVOICING,
        },
        {
          stepKey: 'pax',
          isValid: false,
          stepMsg: TEXTS.VALIDATOR_PAX,
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (showValidator) {
      validate(validationSteps.steps);
    }
  }, [cart.selectedInvoicingAddress, cart.userData, cart.selectedPassengers]);

  const validate = itemsToValidate => {
    let allValid = true;
    let itemsUpdated = itemsToValidate;

    function checkInvoicing() {
      let isValid = false;

      isValid = !!(
        cart.selectedInvoicingAddress &&
        cart.selectedInvoicingAddress['id'] &&
        cart.userData &&
        (cart.userData['cpf'] || cart.userData['identityDocument'])
      );

      return isValid;
    }

    function checkPax() {
      let isValid = false;

      if (selectedPassengers.length >= minimumSeats) isValid = true;

      return isValid;
    }

    function validator(item) {
      let isValid = false;
      switch (item.stepKey) {
        case 'invoicing':
          isValid = checkInvoicing();
          break;
        case 'pax':
          isValid = checkPax();
          break;
        default:
          break;
      }
      return isValid;
    }

    for (let i = 0; i < itemsToValidate.length; i++) {
      const element = itemsToValidate[i];
      let isValid = validator(element);
      itemsUpdated[i] = { ...itemsToValidate[i], isValid: isValid };
      if (!isValid) allValid = false;
    }

    setValidationSteps(oldState => ({
      ...oldState,
      isValid: allValid,
      steps: [...itemsUpdated],
    }));

    return allValid;
  };

  const handleCloseValidator = () => {
    setShowValidator(false);
    setHasBackdrop(false);
    setTabSectionHasFocus(false);
    setValidationSteps(oldState => ({
      ...oldState,
    }));
  };

  const handleChangeValidatorStep = stepKey => {
    switch (stepKey) {
      case 'invoicing':
        setTabActiveIndex(0);
        break;
      case 'pax':
        setTabActiveIndex(1);
        break;
      default:
        break;
    }
  };

  const handleIsAddingCard = active => {
    if (validate(validationSteps.steps)) {
      setHasBackdrop(active);
      setIsCreatingCard(active);
    } else {
      setShowValidator(true);
      setHasBackdrop(true);
      setTabSectionHasFocus(true);
    }
  };

  const handleIsSelectingPassengers = active => {
    setShowValidator(false);
    setTabSectionHasFocus(false);
    setHasBackdrop(active);
    setIsSelectingPassengers(active);
  };

  const handleIsConfirmingPayment = active => {
    if (validate(validationSteps.steps)) {
      setHasBackdrop(active);
      setIsConfirmingPayment(active);
      return true;
    } else {
      setShowValidator(true);
      setHasBackdrop(true);
      setTabSectionHasFocus(true);
      return false;
    }
  };

  const handleBackHome = () => {
    navigate('/');
  };

  const handlePaymentFailure = () => {
    handleIsConfirmingPayment(false);
    setPaymentConfirm(null);
  };

  const preparePaymentData = () => {
    return {
      cart: formatCartForPayment(cart.cartData, cart.selectedPassengers),
      invoice: formatInvoiceForPayment(
        cart.selectedInvoicingAddress,
        cart.userData
      ),
      passengers: formatPassengersForPayment(cart.selectedPassengers),
      payments: formatPaymentMethodForPayment(
        cart.selectedPaymentMethod,
        cart.selectedCardCode,
        cart.selectedInstallments
      ),
    };
  };

  const getProductDataInternationalCard = () => {
    const data = {
      cart: cart.cartData,
      invoice: {
        selectedInvoicingAddress: cart.selectedInvoicingAddress,
        userData: cart.userData,
      },
      passengers: cart.selectedPassengers,
    };
    return data;
  };

  const handleExecPayment = async () => {
    setLoadingMsg(TEXTS.LOADING_MSG_PAYMENT);
    setIsLoading(true);

    const data = preparePaymentData();
    const response = await execPayment(data);

    setLoadingMsg('');
    setIsLoading(false);

    if (response.data.result.status === 0) {
      setPaymentConfirm(RESULT.SUCCESS);
    } else {
      setError('payment', getRequestErrorMessage(response.data));
      setPaymentConfirm(RESULT.FAILURE);
    }
  };

  return (
    <>
      {isLoading && (
        <div className={Style.loadingWrapper}>
          <Loader>
            <p>{loadingMsg}</p>
          </Loader>
        </div>
      )}
      {showError && (
        <ErrorAlert
          title={TEXTS.ERROR_TITLE}
          subtitle={TEXTS.ERROR_SUBTITLE}
          description={TEXTS.ERROR_ADVICE}
          errorMsg={TEXTS.ERROR_MSG}
          buttonLabel={TEXTS.ERROR_BUTTON}
          onClick={handleBackHome}
        />
      )}
      {paymentConfirm && (
        <PaymentConfirmation
          closeSuccess={handleBackHome}
          closeFailure={handlePaymentFailure}
          success={paymentConfirm === RESULT.SUCCESS}
        />
      )}
      <div className={Style.checkoutContainer}>
        <div
          className={[
            Style.backdropContainer,
            hasBackdrop ? Style.active : '',
          ].join(' ')}
        ></div>
        <div className={Style.leftSide}>
          <section className={Style.tripWrapper}>
            <TripSection data={{ cart: cart.cartData }} />
          </section>

          <section
            className={[
              Style.creditCardSelectorWrapper,
              isCreatingCard ? Style.hidden : '',
            ].join(' ')}
          >
            <CreditCardSelector />
          </section>
        </div>

        <div className={Style.rightSide}>
          <section
            className={[
              Style.invoicingPaxWrapper,
              tabSectionHasFocus ? Style.isFocused : '',
            ].join(' ')}
          >
            {showValidator && (
              <div className={Style.invoicingPaxAlert}>
                <ValidatorWizard
                  steps={validationSteps.steps}
                  onClose={handleCloseValidator}
                  userName={cart.userData ? cart.userData['fullname'] : ''}
                  onChangeStep={handleChangeValidatorStep}
                ></ValidatorWizard>
              </div>
            )}
            <TabSection
              headerClassName={Style.tabHeader}
              activeIndex={tabActiveIndex}
              sections={[
                {
                  title: TEXTS.INVOINCING_DATA,
                  content: (
                    <div
                      className={[
                        Style.passengersSelectorWrapper,
                        isSelectingPassengers ? Style.isFocused : '',
                      ].join(' ')}
                    >
                      <InvoicingManager
                        nameLabel={TEXTS.NAME_LABEL}
                        editButton={TEXTS.EDIT_BUTTON}
                        noAddress={TEXTS.NO_ADDRESS}
                      />
                    </div>
                  ),
                },
                {
                  title: TEXTS.PASSENGERS,
                  test: 'checkout.tabPassenger',
                  content: (
                    <div
                      className={[
                        Style.passengersSelectorWrapper,
                        isSelectingPassengers ? Style.isFocused : '',
                      ].join(' ')}
                    >
                      <PassengersManager
                        setFocusSelection={active =>
                          handleIsSelectingPassengers(active)
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
          </section>

          <section
            className={[
              Style.paymentOptionsWrapper,
              isCreatingCard || isConfirmingPayment ? Style.isFocused : '',
            ].join(' ')}
          >
            <>
              <InstallmentsSelector
                onSelect={() => handleIsConfirmingPayment(true)}
                onCancelSelection={() => handleIsConfirmingPayment(false)}
                onExecPayment={handleExecPayment}
                currency={cart.cartData ? cart.cartData.currency : null}
              />
            </>
            {isCreatingCard ? (
              <div className={Style.newCreditCardWrapper}>
                <CreditCardCreator
                  getProduct={getProductDataInternationalCard}
                  //onCreateCard={() => handleIsAddingCard(false)}
                  onClose={() => handleIsAddingCard(false)}
                />
              </div>
            ) : (
              !isConfirmingPayment && (
                <div
                  className={Style.newCreditCardButton}
                  data-test="checkout.addNewCard"
                >
                  <span onClick={() => handleIsAddingCard(true)}>
                    {TEXTS.ADD_NEW_CARD}
                  </span>
                </div>
              )
            )}
          </section>
        </div>
      </div>

      <Dialog open={showEVEDialog} setOpen={setShowEVEDialog} persistent>
        <FormCheckout
          hasCheckbox={hasCheckbox}
          flightId={flightId}
          onSuccess={() => setShowEVEDialog(false)}
        />
      </Dialog>
    </>
  );
};

export default Checkout;
