import styled, { css, keyframes } from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
`;

const COLORS = {
  white: '#ffffff',
  ligthGrey: '#E0E0E0',
  darkGrey: '#858585',
  labelSmall: '##BDBDBD',
  buttonGreen: '#01BEA4',
  disabledGrey: '#E0E0E0',
};

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${COLORS.white};
  font-size: 10px;
  min-height: 120px;
  max-height: 550px;
  height: auto;
  padding: 15px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #01887b;
    border-radius: 10px;
    border: 2px solid #fff;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #01ae9d;
  }

  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
`;

const Form = styled.form`
  width: 100%;

  input {
    font-size: 1rem;
  }

  label span {
    font-size: 1rem;
  }

  @media (max-width: 720px) {
    padding-top: 20px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #01887b;
      border-radius: 10px;
      border: 2px solid #fff;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #01ae9d;
    }
  }
`;

const Title = styled.h1`
  border-bottom: 1px solid ${COLORS.ligthGrey};
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.4em;
  padding: 10px 0;
  white-space: nowrap;
`;

const Subtitle = styled.p`
  font-size: 1em;

  .popupInfo {
    width: 100%;
    display: block;
    padding: 10px;
    position: relative;
    top: -40px;
    background-color: #01bea4;
    color: white;
    opacity: 0;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  .icon:hover ~ .popupInfo {
    opacity: 1;
    transform: translateY(-80%);
  }

  .icon {
    z-index: 4;
    cursor: pointer;
  }
`;

const InputColumn = styled.div`
  width: 47%;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  label {
    width: 100%;
  }

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-between;
  position: relative;

  .suffix {
    position: absolute;
    right: 0px;
    top: 0;
    width: 50px;
    height: calc(100% - 23px);
    display: grid;
    place-content: center;
    font-size: 14px;
    font-weight: bold;
  }
`;

const buttonDefaultTheme = css`
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.buttonGreen};
  color: ${COLORS.buttonGreen};
`;

const buttonDisabledTheme = css`
  background-color: ${COLORS.disabledGrey};
  border: 2px solid ${COLORS.disabledGrey};
  pointer-events: none;
  color: ${COLORS.darkGrey};
`;

const buttonActiveTheme = css`
  background-color: ${COLORS.buttonGreen};
  border: 2px solid ${COLORS.buttonGreen};
  color: ${COLORS.white};
`;

const getButtonTheme = theme => {
  return {
    default: buttonDefaultTheme,
    disabled: buttonDisabledTheme,
    active: buttonActiveTheme,
  }[theme];
};

const Button = styled.button`
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 14px 0;
  width: 100%;

  ${({ theme }) => getButtonTheme(theme)};
`;

const BtnContainer = styled.div`
  display: flex;
  margin-top: 17px;
  justify-content: flex-end;
  ${Button} {
    margin-right: 10px;
    &:last-child {
      margin: 0;
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: relative;

  input {
    margin-right: 10px;
    margin-left: 5px;

    cursor: pointer;
    transform: scale(1.5);
  }

  .checkboxLabel {
    position: absolute;
    height: calc(100% - 14px);
    display: flex;
    align-items: center;
    left: 50px;
    bottom: 14px;
  }
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #555;
  margin: 10px 0 33px 0;
`;

const Info = styled.p`
  font-size: 1rem;
`;

const showMsg = keyframes`
 from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ErrorMSG = styled.div`
  width: 70%;
  margin: 10px auto 0 auto;
  text-align: center;
  color: white;
  background-color: #ca5050;
  padding: 5px 0;
  border-radius: 10px;
  font-weight: 500;
  animation: ${showMsg} 0.3s ease-in 1 forwards;
`;

export {
  Container,
  FormWrapper,
  Form,
  Title,
  InputContainer,
  InputColumn,
  InputRow,
  BtnContainer,
  Button,
  Line,
  Label,
  Info,
  Subtitle,
  ErrorMSG,
};
