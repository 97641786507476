// TODO: REFACTORING
import React from 'react';
import * as Styled from './Checkbox.style';

const Checkbox = ({
  selected = true,
  text,
  onClick,
  fullWidth = false,
  labelColor = '#b0b0bc',
}) => {
  return (
    <Styled.Container onClick={onClick} fullWidth={fullWidth}>
      <Styled.Checkbox selected={selected} />
      {text && (
        <Styled.Label labelColor={labelColor}>
          <span>{text}</span>
        </Styled.Label>
      )}
    </Styled.Container>
  );
};

export default Checkbox;
