import styled from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const ValidatorWizardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 260px;
  height: auto;
  min-height: 100px;
  padding: 10px;

  background-color: white;
  /* border: 2px solid #00bfa5; */
  border-radius: 12px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 17px #6464646b;

  transition: all ease 0.5s;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(5.6);

  height: 35px;
  width: 35px;

  > img {
    height: 17px;
    width: 17px;
  }
`;

export const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: #00bfa5;
  margin-bottom: 15px;
`;

export const Info = styled.div`
  font-size: 19px;
  font-weight: 500;
  color: #222;
  margin-bottom: 15px;
`;

export const StepsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Steps = styled.div`
  display: flex;
`;

export const Dot = styled.div`
  background-color: ${props => (props.isValid ? '#00bfa5' : '#ddd')};
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 5px;
`;
