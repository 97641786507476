import { useStore } from 'react-redux';
import { addError } from '@store/reducers/errors/operations';

/**
 * Dispatches errors to redux
 * @return function
 */
function useErrorHandler() {
  const store = useStore();

  const onReceiveError = (dispatcher, error) => {
    store.dispatch(addError(error, dispatcher));
  };

  return onReceiveError;
}

export default useErrorHandler;
